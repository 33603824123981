import React, { FunctionComponent } from 'react';
import {
  CheckBox as CheckBoxIcon,
  CheckBoxOutlineBlank,
} from '@mui/icons-material';
import { isEqual, map } from 'lodash';

import match from 'autosuggest-highlight/match';
import parse from 'autosuggest-highlight/parse';
import { Autocomplete, Checkbox, TextField, Typography } from '@mui/material';

import { Dropdown, ModelInstance, RowIdentifier } from '../../types/models';

const icon = <CheckBoxOutlineBlank fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

interface IInputsMultiSelectProps {
  /**
   * List of inputs to be populated
   */
  inputsList: RowIdentifier[];
  /**
   * List of inputs selected
   */
  preSelectedInputs?: RowIdentifier[];
  /**
   * The onChange handler
   * @param input
   */
  handleInputChange: (input: RowIdentifier[]) => void;
  /**
   * The model
   * This component will be disabled until a model is passed in
   */
  modelInstance?: ModelInstance;
  size?: 'small' | 'medium';
  hasError?: boolean;
  selectAll?: boolean;
}

const InputsMultiSelect: FunctionComponent<IInputsMultiSelectProps> = ({
  inputsList,
  preSelectedInputs,
  handleInputChange,
  modelInstance,
  size = 'small',
  selectAll = false,
}) => {
  const getInputList = () => {
    if (selectAll) {
      return [
        {
          Name: Dropdown.Select_All,
          DimNumber: null,
          DisableInputEdit: false,
          DisableOutputAggregation: false,
          FormatKey: 0,
          GroupID: undefined,
          InputCategory: '',
          ModelInstanceID: modelInstance?.id || 0,
          PreviousGroupName: null,
          TabName: '',
          Type: 'Input' as const,
          created_at: '',
          id: 999999,
          value: 9999999,
        },
        ...inputsList,
      ];
    } else {
      return inputsList;
    }
  }

  const handleChange = (event: React.ChangeEvent<{}>, value: RowIdentifier[]) => {
    const target = event.target as HTMLElement;
    const isSelectAllClick = target.textContent === Dropdown.Select_All || target.closest('li')?.textContent === Dropdown.Select_All;

    if (isSelectAllClick && preSelectedInputs?.length !== inputsList.length) {
      handleInputChange(inputsList);
    } else if (isSelectAllClick && preSelectedInputs?.length === inputsList.length) {
      handleInputChange([]);
    } else {
      handleInputChange(value);
    }
  }

  const getSelectedFlag = (selected: boolean) => {
    if (preSelectedInputs?.length === inputsList.length) {
      return true;
    } else {
      return selected;
    }
  }


  return (
    <Autocomplete
      multiple
      ChipProps={{
        size: 'small',
      }}
      value={preSelectedInputs}
      onChange={(event, value) => handleChange(event, value)}
      disableCloseOnSelect
      options={inputsList ? getInputList() : []}
      getOptionLabel={(option) => option.Name}
      isOptionEqualToValue={(option, value) => isEqual(option, value)}
      renderOption={(props, option, { inputValue, selected }) => {
        const matches = match(option.Name, inputValue, {
          insideWords: true,
          findAllOccurrences: true,
        });
        const parts = parse(option.Name, matches);
        return (
          <Typography component="li" {...props} noWrap>
            <Checkbox
              icon={icon}
              checkedIcon={checkedIcon}
              style={{ marginRight: 8 }}
              checked={selectAll ? getSelectedFlag(selected) : selected}
            />
            {map(parts, (part, index) => (
              <span
                key={index}
                style={{
                  fontWeight: part.highlight ? 700 : 400,
                }}
              >
                {part.text}
              </span>
            ))}
          </Typography>
        );
      }}
      disabled={modelInstance === undefined}
      renderInput={(params) => (
        <TextField {...params} label="Select inputs" fullWidth size={size} />
      )}
    />
  );
};

export default InputsMultiSelect;
