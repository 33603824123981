import React, {
  FunctionComponent,
  useContext,
  useEffect,
  useRef,
  useMemo,
  ChangeEvent,
} from 'react';
import {
  capitalize,
  cloneDeep,
  compact,
  entries,
  every,
  filter,
  find,
  findIndex,
  forEach,
  get,
  groupBy,
  has,
  head,
  includes,
  isEmpty,
  isEqual,
  isObject,
  isUndefined,
  keys,
  map,
  reduce,
  reject,
  some,
  sortBy,
  startsWith,
  toLower,
  trimStart,
  uniq,
  values,
} from 'lodash';

import { useSnackbar } from 'notistack';

import {
  Alert,
  Box,
  Button,
  IconButton,
  TextField,
  FormHelperText,
  Card,
  CardContent,
  Input,
  FormControl,
  Select,
  MenuItem,
  Divider,
  Typography,
  InputLabel,
  Avatar,
  List,
  ListItemButton,
  ListItem,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  TableFooter,
} from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { red } from '@mui/material/colors';
import { Add, Edit, Check, Clear, Delete } from '@mui/icons-material';

import { LoadingButton } from '@mui/lab';

import {
  Model,
  ModelInstance,
  RowIdentifier,
  Scenario,
  Source,
  Timescale,
} from '../../../../types/models';
import SelectModel from '../../../shared/SelectModel';
import SelectModelInstance from '../../../shared/SelectModelInstance';
import ScenariosMultiSelect from '../../../Visualization/ScenariosMultiSelect';

import {
  dataSourceCutTypes,
  filterObject,
  portfolioNumScenarios,
} from '../../../Visualization/visualizationConstants';
import OutputsMultiSelect from '../../../Visualization/OutputsMultiSelect';
import { byName, hasDuplicates } from '../../../../utils/misc';

import { getTimescalesForModelInstance } from '../../../../utils/timescales';

import * as API from '../../../../services/API';

import APICacheContext from '../../../shared/APICacheContext';

import ConditionalWrap from '../../../shared/ConditionalWrap';

import TimescalesMultiSelect from '../../TimescalesMultiSelect';
import InputsMultiSelect from '../../InputsMultiSelect';
import DimensionInstancesMultiSelect from '../../DimensionInstancesMultiSelect';

import { MAX_DIMENSIONS } from '../../portfolioDashboardConstants';

import { IPortfolioReportStateData } from '../../index';
import { ConfirmationMessageForUnSavedChanges } from '../index';

import useSetState from '../../../../hooks/useSetState';

import PopupDialog from '../../../shared/PopupDialog';

import CenteredSpinner from '../../../shared/CenteredSpinner';

import { usePrompt } from '../../../../hooks/useCallbackPrompt';

import {
  PortfolioDataSourceData,
  PortfolioDataSourceDataFinalizedFlag,
} from '../../types';

import { useWindowDimensions } from '../../../../hooks/useWindowDimensions';

import { useData } from '../../../../hooks/useData';

import DeleteDataCutDialog from './DeleteDataCutDialog';
import RenameDataCutDialog from './RenameDataCutDialog';
import DeleteDimensionDialog from './DeleteDimensionDialog';
import FinalizeDimensionDialog from './FinalizeDimensionDialog';

export interface IDimensionsList {
  id: number;
  fieldName: string;
  editable: boolean;
  fieldValue: string;
  originalFieldValue: string;
  onChangeFieldValue: string;
}

interface IDataCutDimensionsListData {
  sourceDimensionId: number;
  sourceDimensionName: string;
  dimension: RowIdentifier | { id: string; Name: 'Unmapped' };
  dimensionInstances: {
    value: RowIdentifier[];
    error: boolean;
  };
}

export interface IDataCutFieldsList {
  id?: number;
  Name: string;
  modelId: {
    value?: Model['id'];
    error: boolean;
    isDirty: boolean;
  };
  modelInstanceId: {
    value?: ModelInstance['id'];
    error: boolean;
    isDirty: boolean;
  };
  modelInstanceLock: {
    value: boolean;
    isDirty: boolean;
  };
  scenarios: {
    value: Scenario[];
    isDirty: boolean;
    error: boolean;
  };
  numScenarios: {
    value: string;
    isDirty: boolean;
    error: boolean;
  };
  rangeType: {
    value?: string;
    error: boolean;
    isDirty: boolean;
  };
  scenariosLock: {
    value: boolean;
    isDirty: boolean;
  };
  cutType: { value?: Source; error: boolean; isDirty: boolean };
  inputs: { value: RowIdentifier[]; error: boolean; isDirty: boolean };
  outputs: { value: RowIdentifier[]; error: boolean; isDirty: boolean };
  timescales: { value: Timescale[]; error: boolean; isDirty: boolean };
  invalidMaxScenariosSelected: boolean;
  dimensionsList: {
    value: IDataCutDimensionsListData[];
    isDirty: boolean;
  };
}

interface IDataCutButtonsList {
  id: number;
  name: string;
  title: string;
  isDirty: boolean;
}

const dataCutFieldsList: IDataCutFieldsList = {
  Name: '',
  modelId: {
    value: undefined,
    error: false,
    isDirty: false,
  },
  modelInstanceId: {
    value: undefined,
    error: false,
    isDirty: false,
  },
  modelInstanceLock: {
    value: true,
    isDirty: false,
  },
  scenarios: {
    value: [],
    isDirty: false,
    error: false,
  },
  numScenarios: {
    value: '',
    isDirty: false,
    error: false,
  },
  rangeType: {
    value: 'max',
    error: false,
    isDirty: false,
  },
  scenariosLock: {
    value: true,
    isDirty: false,
  },
  cutType: {
    value: undefined,
    error: false,
    isDirty: false,
  },
  inputs: {
    value: [],
    error: false,
    isDirty: false,
  },
  outputs: {
    value: [],
    error: false,
    isDirty: false,
  },
  timescales: {
    value: [],
    error: false,
    isDirty: false,
  },
  invalidMaxScenariosSelected: false,
  dimensionsList: {
    value: [],
    isDirty: false,
  },
};

type ButtonType = 'sourceDimension' | string;

const FORBIDDEN_CODE: string = '403';

interface IPortfolioSourceTypeProps {
  clientId?: number;
  selectedReportDataSourceData?: PortfolioDataSourceData;
  isOpen?: boolean;
  onClose?: () => void;
  showComponentInADialog: boolean;
  selectedReport?: IPortfolioReportStateData;
  refreshReports: () => void;
  refreshDataSource: (id?: PortfolioDataSourceData['id']) => void;
  isThereAnUnsavedComponent: (bool: boolean) => void;
}

interface IPortfolioSourceTypeState {
  dimensionsList: IDimensionsList[];
  originalDimensionsList: IDimensionsList[];
  buttonType: ButtonType;
  onSaveDimensions: boolean;
  onFinalizeDimensions: boolean;
  dataCutList: Record<string, IDataCutFieldsList>;
  originalDataCutList: Record<string, IDataCutFieldsList>;
  dataCutButtonsList: IDataCutButtonsList[];
  isRenameDataCutModalOpen: boolean;
  renameDataCutObject?: IDataCutFieldsList;
  saveDirtyDataCut: boolean;
  navigateButton: string;
  isLoading: boolean;
  allUnmappedDimensions: boolean;
  isDeleteDataCutModalOpen: boolean;
  deleteDataCutObject?: IDataCutFieldsList;
  onFocusedInput: string;
  onSaveLoading: boolean;
  isDeleteDimensionModalOpen: boolean;
  deleteDimensionObject?: IDimensionsList;
  isFinalizeDimensionsModalOpen: boolean;
  dimensionIdsList: number[];
  isAccessDenied: boolean;
}

const initialState: IPortfolioSourceTypeState = {
  dimensionsList: [],
  originalDimensionsList: [],
  buttonType: 'sourceDimension',
  onSaveDimensions: false,
  onFinalizeDimensions: false,
  dataCutList: {},
  originalDataCutList: {},
  dataCutButtonsList: [],
  isRenameDataCutModalOpen: false,
  renameDataCutObject: undefined,
  saveDirtyDataCut: false,
  navigateButton: '',
  isLoading: false,
  allUnmappedDimensions: false,
  isDeleteDataCutModalOpen: false,
  deleteDataCutObject: undefined,
  onFocusedInput: '',
  onSaveLoading: false,
  isDeleteDimensionModalOpen: false,
  deleteDimensionObject: undefined,
  isFinalizeDimensionsModalOpen: false,
  dimensionIdsList: [],
  isAccessDenied: false,
};

const selectBtnStyle = {
  backgroundColor: '#f5f8fa',
  color: '#182026',
};

const EditPortfolioSourceType: FunctionComponent<IPortfolioSourceTypeProps> = (
  props: IPortfolioSourceTypeProps
) => {
  const { enqueueSnackbar } = useSnackbar();
  const { width } = useWindowDimensions();

  const [state, setState] =
    useSetState<IPortfolioSourceTypeState>(initialState);

  const refs = new Map<number, HTMLInputElement>();
  const dataCutButtonsListContainerRef = useRef<HTMLDivElement | null>(null);

  const apiContext = useContext(APICacheContext);

  useEffect(() => {
    if (props.selectedReportDataSourceData?.id) {
      setState({ ...initialState, isLoading: true });
      refreshData();
    }
  }, [props.selectedReportDataSourceData?.id, props.isOpen]);

  useEffect(() => {
    const editableFieldIndex: number = findIndex(
      state.dimensionsList,
      'editable'
    );
    const refValues: HTMLInputElement[] = Array.from(refs.values());
    refValues[editableFieldIndex] && refValues[editableFieldIndex].focus();
  }, [state.dimensionsList]);

  const scrollToBottom = (): void => {
    const dataCutButtonsListContainerRefValue: HTMLDivElement =
      dataCutButtonsListContainerRef &&
      (dataCutButtonsListContainerRef.current as HTMLDivElement);
    if (dataCutButtonsListContainerRefValue) {
      dataCutButtonsListContainerRefValue.scrollIntoView({
        behavior: 'smooth',
      });
    }
  };

  useEffect(() => {
    scrollToBottom();
  }, [state.dataCutButtonsList]);

  useEffect(() => {
    const dataCutList = state.dataCutList[state.buttonType];

    const isFormDirty = some(
      compact(
        map(values(dataCutList), (item) => {
          if (isObject(item)) {
            return item.isDirty;
          }
        })
      ),
      (item) => item
    );
    setState({
      dataCutButtonsList: map(state.dataCutButtonsList, (item) => {
        if (item.name === state.buttonType) {
          item.isDirty = isFormDirty;
        }
        return item;
      }),
    });
  }, [state.dataCutList[state.buttonType]]);

  const { data: dataSourceData, refresh: refreshDataSource } = useData<{
    data?: any[];
  }>(
    () => ({
      data:
        props.selectedReportDataSourceData !== undefined
          ? `/portfolio_datasources/${props.selectedReportDataSourceData?.id}`
          : undefined,
    }),
    [],
    true
  );

  useEffect(() => {
    if (!isEmpty(dataSourceData.data)) {
      setState({
        onFinalizeDimensions:
          props.selectedReportDataSourceData?.Finalized ===
          PortfolioDataSourceDataFinalizedFlag.Finalized,
      });
    }
  }, [dataSourceData.data]);

  const { data } = useData<{
    modelInstance?: ModelInstance;
    scenarios?: Scenario[];
    rowIdentifiers?: RowIdentifier[];
  }>(() => {
    return {
      modelInstance:
        !isEmpty(state.dataCutList) &&
        state.buttonType !== 'sourceDimension' &&
        get(state.dataCutList[state.buttonType], 'modelInstanceId.value') !==
          undefined &&
        !isEmpty(state.dataCutList) &&
        state.buttonType !== 'sourceDimension' &&
        get(state.dataCutList[state.buttonType], 'modelId.value') !== undefined
          ? `/instances/${
              !isEmpty(state.dataCutList) &&
              get(state.dataCutList[state.buttonType], 'modelInstanceId.value')
            }`
          : undefined,
      scenarios:
        !isEmpty(state.dataCutList) &&
        state.buttonType !== 'sourceDimension' &&
        get(state.dataCutList[state.buttonType], 'modelInstanceId.value') !==
          undefined
          ? `/instances/${
              !isEmpty(state.dataCutList) &&
              get(state.dataCutList[state.buttonType], 'modelInstanceId.value')
            }/scenarios_load/finished`
          : undefined,
      rowIdentifiers:
        !isEmpty(state.dataCutList) &&
        state.buttonType !== 'sourceDimension' &&
        get(state.dataCutList[state.buttonType], 'modelInstanceId.value') !==
          undefined
          ? `/instances/${
              !isEmpty(state.dataCutList) &&
              get(state.dataCutList[state.buttonType], 'modelInstanceId.value')
            }/row_identifiers`
          : undefined,
    };
  }, [state.dataCutList[state.buttonType]],
  true // for force refresh
);

  const onHandleButtonTypeChange = (type: ButtonType): void => {
    const dataCut: IDataCutButtonsList | undefined = find(
      state.dataCutButtonsList,
      {
        name: state.buttonType,
      }
    );
    if (dataCut && (dataCut as IDataCutButtonsList).isDirty) {
      setState({ navigateButton: type, saveDirtyDataCut: true });
    } else {
      if (type !== state.buttonType) {
        setState({ buttonType: type });
      }
    }
  };

  const handleDimensionNameEdit = (i: IDimensionsList['id']): void => {
    const result = map(state.dimensionsList, (item) => {
      item.editable = false;
      item.editable = item.id === i;
      return item;
    });

    setState({
      dimensionsList: result,
    });
  };

  const handleDimensionDelete = async (
    id: IDimensionsList['id']
  ): Promise<void> => {
    const dimensionItem = find(state.dimensionsList, { id }) as IDimensionsList;
    setState({
      deleteDimensionObject: dimensionItem,
      isDeleteDimensionModalOpen: true,
    });
  };

  const addNewDimension = (): void => {
    setState({
      dimensionsList: map(state.dimensionsList, (item) => {
        item.editable = false;
        return item;
      }).concat({
        id: state.dimensionsList.length + 1,
        editable: true,
        fieldName: `dimension${state.dimensionsList.length + 1}`,
        fieldValue: '',
        originalFieldValue: '',
        onChangeFieldValue: '',
      }),
    });
  };

  const onHandleDimensionInputChange = (
    event: ChangeEvent<HTMLInputElement>,
    id: IDimensionsList['id']
  ): void => {
    const newDimensionsList = map(state.dimensionsList, (item) => {
      if (item.id === id) {
        item.onChangeFieldValue = trimStart(
          event.target.value.substring(0, 40)
        );
      }
      return item;
    });

    setState({ dimensionsList: newDimensionsList });
  };

  const onSaveDimensionInputChange = async (
    id: IDimensionsList['id']
  ): Promise<void> => {
    const dimensionItem = find(state.dimensionsList, { id }) as IDimensionsList;
    if (!dimensionItem.onChangeFieldValue) {
      enqueueSnackbar('The dimension should contain a value.', {
        variant: 'error',
      });
    } else if (!dimensionItem.originalFieldValue) {
      const newDimensionsList = map(state.dimensionsList, (item) => {
        if (item.id === id) {
          item.fieldValue = item.onChangeFieldValue;
        }
        return item;
      });
      const dimensionValues = map(newDimensionsList, (item) =>
        toLower(item.fieldValue)
      );

      if (hasDuplicates(dimensionValues)) {
        enqueueSnackbar(
          'Dimension names must be unique. Please change your dimension name to continue.',
          {
            variant: 'error',
          }
        );
      } else {
        setState({ dimensionsList: newDimensionsList, isLoading: true });
        handleDimensionInputBlur(id);
        try {
          await API.create(
            `/portfolio_datasources/${props.selectedReportDataSourceData?.id}/portfolio_dimensions/`,
            {
              dimensions: [
                {
                  DimNumber: id - 1,
                  Name: (find(newDimensionsList, { id }) as IDimensionsList)
                    .fieldValue,
                },
              ],
              Finalized: 0,
            }
          );
          enqueueSnackbar(
            `${dimensionItem.fieldValue} dimension created successfully.`,
            {
              variant: 'success',
            }
          );
          setState({ isLoading: false });
          await refreshData();
        } catch (e) {
          console.error(e);
        }
      }
    } else {
      const dimensionValues = map(state.dimensionsList, (item) =>
        toLower(item.fieldValue)
      );
      const originalDimensionValues = map(
        state.originalDimensionsList,
        (item) => toLower(item.fieldValue)
      );
      const dimensionValuesChanged = map(state.dimensionsList, (item) =>
        toLower(item.onChangeFieldValue)
      );
      if (
        !isEqual(dimensionValues, originalDimensionValues) ||
        hasDuplicates(dimensionValuesChanged)
      ) {
        enqueueSnackbar(
          'Dimension names must be unique. Please change your dimension name to continue.',
          {
            variant: 'error',
          }
        );
      } else {
        if (!isEqual(dimensionValues, dimensionValuesChanged)) {
          try {
            await API.update(
              `/portfolio_datasources/${props.selectedReportDataSourceData?.id}/portfolio_dimensions/${dimensionItem.id}`,
              {
                Name: dimensionItem.onChangeFieldValue,
              }
            );
            await refreshData();
            enqueueSnackbar(`Dimension updated successfully.`, {
              variant: 'success',
            });
          } catch (e) {
            enqueueSnackbar((e as any).body.message, {
              variant: 'error',
            });
          }
        } else {
          setState({
            dimensionsList: map(state.dimensionsList, (item) => {
              if (item.id === id) {
                item.editable = false;
              }
              return item;
            }),
          });
        }
      }
    }
  };

  const onCancelDimensionInputChange = (id: IDimensionsList['id']): void => {
    if (includes(state.dimensionIdsList, id)) {
      const newDimensionsList = map(state.dimensionsList, (item) => {
        if (item.id === id) {
          item.fieldValue = item.originalFieldValue;
          item.onChangeFieldValue = item.originalFieldValue;
          item.editable = false;
        }
        return item;
      });

      setState({ dimensionsList: newDimensionsList });
      handleDimensionInputBlur(id);
    } else {
      const newDimensionsList = reject(state.dimensionsList, { id });
      setState({ dimensionsList: newDimensionsList });
    }
  };

  const handleDimensionInputBlur = (id: number): void => {
    const newDimensionsList = map(state.dimensionsList, (item) => {
      if (item.id === id && item.fieldValue) {
        item.editable = false;
      }
      return item;
    });

    setState({ dimensionsList: newDimensionsList });
  };

  const onFinalizeDimensions = async (): Promise<void> => {
    const dimensionValues = map(state.dimensionsList, (item) =>
      toLower(item.fieldValue)
    );
    const filteredDimensionValues = compact(dimensionValues);
    if (isEqual(dimensionValues, filteredDimensionValues)) {
      if (hasDuplicates(dimensionValues)) {
        setState({
          onSaveDimensions: false,
          onFinalizeDimensions: false,
        });
        enqueueSnackbar(
          'One or more dimensions have a similar name. Please rename your dimensions to continue.',
          {
            variant: 'error',
          }
        );
      } else if (
        every(state.dimensionsList, 'fieldValue') &&
        !hasDuplicates(dimensionValues)
      ) {
        setState({ isFinalizeDimensionsModalOpen: true });
      } else {
        setState({
          onSaveDimensions: false,
          onFinalizeDimensions: false,
        });
        enqueueSnackbar(
          'Please delete the unused dimension fields to continue.',
          {
            variant: 'error',
          }
        );
      }
    } else {
      enqueueSnackbar(
        'You must have atleast one dimension saved to continue.',
        {
          variant: 'error',
        }
      );
    }
  };

  const addNewDataCut = (): void => {
    if (isEmpty(state.dimensionsList)) {
      setState({ onSaveDimensions: false });
      enqueueSnackbar('Please add atleast one source dimension to continue.', {
        variant: 'error',
      });
    } else if (state.onFinalizeDimensions) {
      if (startsWith(state.buttonType, 'dataCut-')) {
        const dataCut = find(state.dataCutButtonsList, {
          name: state.buttonType,
        });
        if (dataCut && (dataCut as IDataCutButtonsList).isDirty) {
          setState({ navigateButton: 'new', saveDirtyDataCut: true });
        } else {
          const dataCutListLength = keys(state.dataCutList).length;
          setState({
            dataCutButtonsList: state.dataCutButtonsList.concat({
              id: dataCutListLength + 1,
              name: `dataCut-${dataCutListLength + 1}`,
              title: `DataCut - ${dataCutListLength + 1}`,
              isDirty: true,
            }),
            dataCutList: {
              ...state.dataCutList,
              [`dataCut-${dataCutListLength + 1}`]: {
                ...dataCutFieldsList,
                Name: `dataCut-${dataCutListLength + 1}`,
                dimensionsList: {
                  value: map(state.dimensionsList, (i, index) => {
                    return {
                      sourceDimensionId: i.id,
                      sourceDimensionName: i.originalFieldValue,
                      dimension: {
                        id: `unmappedDimension-${index}`,
                        Name: 'Unmapped',
                      },
                      dimensionInstances: {
                        value: [],
                        error: false,
                      },
                    };
                  }),
                  isDirty: false,
                },
              },
            },
            buttonType: `dataCut-${dataCutListLength + 1}`,
          });
        }
      } else {
        const dataCutListLength = keys(state.dataCutList).length;
        setState({
          dataCutButtonsList: state.dataCutButtonsList.concat({
            id: dataCutListLength + 1,
            name: `dataCut-${dataCutListLength + 1}`,
            title: `DataCut - ${dataCutListLength + 1}`,
            isDirty: true,
          }),
          dataCutList: {
            ...state.dataCutList,
            [`dataCut-${dataCutListLength + 1}`]: {
              ...dataCutFieldsList,
              Name: `dataCut-${dataCutListLength + 1}`,
              dimensionsList: {
                value: map(state.dimensionsList, (i, index) => {
                  return {
                    sourceDimensionId: i.id,
                    sourceDimensionName: i.originalFieldValue,
                    dimension: {
                      id: `unmappedDimension-${index}`,
                      Name: 'Unmapped',
                    },
                    dimensionInstances: {
                      value: [],
                      error: false,
                    },
                  };
                }),
                isDirty: false,
              },
            },
          },
          buttonType: `dataCut-${dataCutListLength + 1}`,
        });
      }
    }
  };

  const onNumScenariosValueChange = (
    e: ChangeEvent<HTMLInputElement>
  ): void => {
    const dataCutList = state.dataCutList[state.buttonType];
    const { value } = e.target;
    if (
      value === '' ||
      (Number(value) >= 1 && Number(value) <= portfolioNumScenarios)
    ) {
      setState({
        dataCutList: {
          ...state.dataCutList,
          [state.buttonType]: {
            ...state.dataCutList[state.buttonType],
            numScenarios: {
              value,
              isDirty: !isEmpty(state.originalDataCutList[state.buttonType])
                ? !isEqual(
                    state.originalDataCutList[state.buttonType].numScenarios
                      .value,
                    value
                  )
                : true,
              error: false,
            },
            rangeType: {
              value: value === '' ? 'max' : dataCutList.rangeType.value,
              error: false,
              isDirty: false,
            },
          },
        },
      });
    }
  };

  const handleChangeScenarios = (scenarios: Scenario[]): void => {
    const dataCutList = state.dataCutList[state.buttonType];

    const scenariosData = scenarios;
    if (Number(dataCutList.numScenarios)) {
      if (scenariosData.length > Number(dataCutList.numScenarios)) {
        setState({
          dataCutList: {
            ...state.dataCutList,
            [state.buttonType]: {
              ...state.dataCutList[state.buttonType],
              invalidMaxScenariosSelected: true,
            },
          },
        });
      } else {
        setState({
          dataCutList: {
            ...state.dataCutList,
            [state.buttonType]: {
              ...state.dataCutList[state.buttonType],
              invalidMaxScenariosSelected: false,
              scenarios: {
                value: scenariosData,
                isDirty: !isEmpty(state.originalDataCutList[state.buttonType])
                  ? !isEqual(
                      state.originalDataCutList[state.buttonType].scenarios
                        .value,
                      scenariosData
                    )
                  : true,
                error: false,
              },
            },
          },
        });
      }
    } else {
      setState({
        dataCutList: {
          ...state.dataCutList,
          [state.buttonType]: {
            ...state.dataCutList[state.buttonType],
            scenarios: {
              value: scenariosData,
              isDirty: !isEmpty(state.originalDataCutList[state.buttonType])
                ? !isEqual(
                    state.originalDataCutList[state.buttonType].scenarios.value,
                    scenariosData
                  )
                : true,
              error: false,
            },
          },
        },
      });
    }
  };

  const handleChangeOutputs = (outputs: RowIdentifier[]): void => {
    const outputsData = outputs;

    setState({
      dataCutList: {
        ...state.dataCutList,
        [state.buttonType]: {
          ...state.dataCutList[state.buttonType],
          outputs: {
            value: outputsData,
            error: false,
            isDirty: !isEmpty(state.originalDataCutList[state.buttonType])
              ? !isEqual(
                  state.originalDataCutList[state.buttonType].outputs.value,
                  outputsData
                )
              : true,
          },
        },
      },
    });
  };

  const handleChangeInputs = (inputs: RowIdentifier[]): void => {
    const inputsData = cloneDeep(inputs);

    setState({
      dataCutList: {
        ...state.dataCutList,
        [state.buttonType]: {
          ...state.dataCutList[state.buttonType],
          inputs: {
            value: inputsData,
            error: false,
            isDirty: !isEmpty(state.originalDataCutList[state.buttonType])
              ? !isEqual(
                  state.originalDataCutList[state.buttonType].inputs.value,
                  inputsData
                )
              : true,
          },
        },
      },
    });
  };

  const handleChangeTimescales = (timescales: Timescale[]): void => {
    const timescalesData = timescales;

    setState({
      dataCutList: {
        ...state.dataCutList,
        [state.buttonType]: {
          ...state.dataCutList[state.buttonType],
          timescales: {
            value: timescalesData,
            error: false,
            isDirty: !isEmpty(state.originalDataCutList[state.buttonType])
              ? !isEqual(
                  state.originalDataCutList[state.buttonType].timescales.value,
                  timescalesData
                )
              : true,
          },
        },
      },
    });
  };

  const handleRenameDataCut = (dataCut?: IDataCutFieldsList): void => {
    setState({
      isRenameDataCutModalOpen: true,
      renameDataCutObject: dataCut,
    });
  };

  const handleDeleteDataCut = (dataCut?: IDataCutFieldsList): void => {
    if (has(dataCut, 'id')) {
      setState({
        isDeleteDataCutModalOpen: true,
        deleteDataCutObject: dataCut,
      });
    } else {
      const newDataCutList = reject(state.dataCutButtonsList, {
        name: (dataCut as IDataCutFieldsList).Name,
      });
      const newResult = map(newDataCutList, (item, index) => {
        return {
          ...item,
          id: index + 1,
        };
      });
      setState({
        dataCutButtonsList: newResult,
        buttonType:
          newResult.length > 0 ? newResult[0].name : 'sourceDimension',
        dataCutList: filterObject(
          state.dataCutList,
          (dataCut as IDataCutFieldsList).Name
        ),
      });
    }
  };

  const handleChangeRenameDataCut = (dataCutName: string): void => {
    if (state.renameDataCutObject && dataCutName) {
      setState({
        isRenameDataCutModalOpen: false,
        dataCutButtonsList: map(state.dataCutButtonsList, (item) => {
          if (item.name === state.buttonType) {
            item.title = dataCutName;
          }
          return item;
        }),
      });
    }
  };

  const handleDimensionInstanceChange = (
    dimensionInstances: RowIdentifier[],
    index: number
  ): void => {
    const dataCutList = state.dataCutList[state.buttonType];

    const dimensionInstancesData = dimensionInstances;

    setState({
      dataCutList: {
        ...state.dataCutList,
        [state.buttonType]: {
          ...dataCutList,
          dimensionsList: {
            value: map(dataCutList.dimensionsList.value, (item, i) => {
              if (i === index) {
                return {
                  ...item,
                  dimensionInstances: {
                    value: dimensionInstancesData,
                    error: false,
                  },
                };
              }
              return item;
            }),
            isDirty: !isEmpty(state.originalDataCutList[state.buttonType])
              ? !isEqual(
                  dataCutList.dimensionsList.value[index].dimensionInstances
                    .value,
                  dimensionInstancesData
                )
              : true,
          },
        },
      },
    });
  };

  const handleSaveDataCut = async (): Promise<void> => {
    const dataCutList = state.dataCutList[state.buttonType];

    const cloneDataCutList = structuredClone(dataCutList);

    const iter = (o: any, errorFlag = true): void => {
      forEach(keys(o), (k) => {
        if (o[k] !== null && isObject(o[k])) {
          iter(o[k]);
          return;
        }
        if (has(o, 'error')) {
          if (o && !o.value) {
            o.error = errorFlag;
          } else if (isObject(o.value) && isEmpty(o.value)) {
            o.error = errorFlag;
          }
        }
      });
    };
    iter(cloneDataCutList);

    forEach(keys(cloneDataCutList), (item) => {
      if (item === 'cutType') {
        if (cloneDataCutList.cutType.value === 'inputs') {
          cloneDataCutList.outputs.error = false;
          cloneDataCutList.timescales.error = false;
        } else if (cloneDataCutList.cutType.value === 'outputs') {
          cloneDataCutList.inputs.error = false;
        }
      }
      if (item === 'scenarios') {
        if (cloneDataCutList.rangeType.value === 'equal') {
          cloneDataCutList.scenarios.error =
            Number(cloneDataCutList.numScenarios.value) !==
            cloneDataCutList.scenarios.value.length;
        }
      }
    });

    const errorCount = compact(
      reject(
        map(values(cloneDataCutList), (item) => {
          if (isObject(item) && has(item, 'error')) {
            return get(item, 'error');
          }
        }),
        isUndefined
      )
    ).length;

    if (errorCount > 0) {
      setState({
        dataCutList: {
          ...state.dataCutList,
          [state.buttonType]: cloneDataCutList,
        },
      });
    }

    const dimensionNames = map(
      map(values(cloneDataCutList.dimensionsList.value), 'dimension'),
      'Name'
    );

    if (every(dimensionNames, (item) => item === 'Unmapped')) {
      setState({ allUnmappedDimensions: true });
    } else {
      setState({ allUnmappedDimensions: false });
      if (errorCount === 0) {
        const dataCut = find(state.dataCutButtonsList, {
          name: state.buttonType,
        });
        try {
          setState({ onSaveLoading: true });
          const result = await API.create<any>(
            dataCutList.id
              ? `/portfolio_datasources/${props.selectedReportDataSourceData?.id}/portfolio_datacuts/${dataCutList.id}`
              : `/portfolio_datasources/${props.selectedReportDataSourceData?.id}/portfolio_datacuts`,
            {
              Name: (dataCut as IDataCutButtonsList).title,
              ModelID: cloneDataCutList.modelId.value,
              MetaData: {
                ModelInstance: cloneDataCutList.modelInstanceId.value,
                ModelInstanceLock: cloneDataCutList.modelInstanceLock.value
                  ? 1
                  : 0,
                NumScenarios:
                  Number(cloneDataCutList.numScenarios.value) === 0
                    ? null
                    : Number(cloneDataCutList.numScenarios.value),
                Scenarios: map(cloneDataCutList.scenarios.value, 'id'),
                ScenarioLock: cloneDataCutList.scenariosLock.value ? 1 : 0,
                RangeType: cloneDataCutList.rangeType.value,
                CutType: cloneDataCutList.cutType.value,
                ...(cloneDataCutList.cutType.value === 'outputs'
                  ? {
                      Outputs: map(cloneDataCutList.outputs.value, 'Name'),
                      Timescales: cloneDataCutList.timescales.value,
                    }
                  : { Inputs: map(cloneDataCutList.inputs.value, 'Name') }),
                DimensionMapping: compact(
                  map(cloneDataCutList.dimensionsList.value, (item, index) => {
                    if (item.dimension.Name !== 'Unmapped') {
                      return {
                        sourceDimension: index,
                        dimension: (item.dimension as RowIdentifier).DimNumber,
                        dimensionInstances: map(
                          item.dimensionInstances.value,
                          'Name'
                        ),
                      };
                    }
                  })
                ),
              },
            }
          );
          if (result) {
            const dataCutList = state.dataCutList[state.buttonType];
            const cloneDataCutList = structuredClone(dataCutList);
            const makeAllFieldsClean = (o: any): void => {
              forEach(keys(o), (k) => {
                if (o[k] !== null && isObject(o[k])) {
                  makeAllFieldsClean(o[k]);
                  return;
                }
                o.isDirty = false;
              });
            };
            makeAllFieldsClean(cloneDataCutList);
            iter(cloneDataCutList, false);
            cloneDataCutList.id = result.id;
            cloneDataCutList.Name = result.Name;
            setState({
              dataCutButtonsList: map(state.dataCutButtonsList, (item) => {
                if (item.name === state.buttonType) {
                  item.isDirty = false;
                }
                return item;
              }),
              onSaveLoading: false,
              originalDataCutList: {
                ...state.originalDataCutList,
                [state.buttonType]: cloneDataCutList,
              },
              dataCutList: {
                ...state.dataCutList,
                [state.buttonType]: cloneDataCutList,
              },
            });
            enqueueSnackbar(
              `${(dataCut as IDataCutButtonsList).title} saved successfully.`,
              {
                variant: 'success',
              }
            );
            props.refreshReports();
          }
        } catch (e: any) {
          setState({ onSaveLoading: false });
          enqueueSnackbar(e.body.message || e.body.errors?._schema?.[0], {
            variant: 'error',
          });
        }
      }
    }
  };

  const refreshData = async (buttonType = 'sourceDimension'): Promise<void> => {
    try {
      const result = await apiContext?.load(
        [
          `/portfolio_datasources/${props.selectedReportDataSourceData?.id}/portfolio_dimensions`,
          `/portfolio_datasources/${props.selectedReportDataSourceData?.id}/portfolio_datacuts`,
          `/portfolio_datasources/${props.selectedReportDataSourceData?.id}`,
        ],
        true
      );
      const modelInstances: ModelInstance['id'][] = uniq(
        map(result && result[1], (item) => item.MetaData.ModelInstance)
      );

      const modelInstancesResult: Promise<{}> = reduce(
        modelInstances,
        async (a, c) => {
          try {
            const scenarios: Scenario[] = await API.load(
              `/instances/${c}/scenarios_load/finished`
            );
            const rowIdentifiers: RowIdentifier[] = await API.load(
              `/instances/${c}/row_identifiers`
            );
            return {
              ...(await a),
              [c]: {
                scenarios,
                rowIdentifiers,
              },
            };
          } catch (error) {
            if (FORBIDDEN_CODE === String((error as any).status)) {
              setState({
                isLoading: false,
                isAccessDenied: true,
              });
            }
            Promise.reject({});
            return {
              ...(await a),
              [c]: {
                scenarios: [],
                rowIdentifiers: [],
              },
            };
          }
        },
        Promise.resolve({})
      );

      modelInstancesResult.then(
        (
          res: Record<
            ModelInstance['id'],
            { scenarios: Scenario[]; rowIdentifiers: RowIdentifier[] }
          >
        ) => {
          if (result) {
            const dimensionsList = map(
              sortBy(result[0], ['DimNumber']),
              (item, index) => {
                return {
                  id: item.id,
                  editable: false,
                  fieldValue: item.Name,
                  originalFieldValue: item.Name,
                  onChangeFieldValue: item.Name,
                  fieldName: `dimension${index + 1}`,
                };
              }
            );
            const dataCutList = reduce(
              result[1],
              (a, c, i) => {
                const scenariosList = res[c.MetaData.ModelInstance].scenarios;
                const rowIdentifiersList =
                  res[c.MetaData.ModelInstance].rowIdentifiers;
                const {
                  Input: inputs,
                  Output: outputs,
                  Dimension: dimensions,
                  DimensionInstance: dimensionInstances,
                } = groupBy(rowIdentifiersList, 'Type');
                return {
                  ...a,
                  [`dataCut-${i + 1}`]: {
                    id: c.id,
                    Name: c.Name,
                    modelId: {
                      value: c.ModelID,
                      error: false,
                      isDirty: false,
                    },
                    modelInstanceId: {
                      value: c.MetaData.ModelInstance,
                      error: false,
                      isDirty: false,
                    },
                    modelInstanceLock: {
                      value: c.MetaData.ModelInstanceLock,
                      isDirty: false,
                    },
                    numScenarios: {
                      value: c.MetaData.NumScenarios
                        ? c.MetaData.NumScenarios.toString()
                        : 0,
                      isDirty: false,
                    },
                    rangeType: {
                      value: c.MetaData.RangeType,
                      error: false,
                      isDirty: false,
                    },
                    scenariosLock: {
                      value: c.MetaData.ScenarioLock,
                      isDirty: false,
                    },
                    scenarios: {
                      value: filter(scenariosList, (item) =>
                        includes(c.MetaData.Scenarios, item.id)
                      ),
                      isDirty: false,
                    },
                    cutType: {
                      value: c.MetaData.CutType,
                      error: false,
                      isDirty: false,
                    },
                    ...(c.MetaData.CutType === 'inputs'
                      ? {
                          inputs: {
                            value: filter(inputs, (item) =>
                              includes(c.MetaData.Inputs, item.Name)
                            ),
                            error: false,
                            isDirty: false,
                          },
                          outputs: {
                            value: [],
                            error: false,
                            isDirty: false,
                          },
                          timescales: {
                            value: [],
                            error: false,
                            isDirty: false,
                          },
                        }
                      : {}),
                    ...(c.MetaData.CutType === 'outputs'
                      ? {
                          outputs: {
                            value: filter(outputs, (item) =>
                              includes(c.MetaData.Outputs, item.Name)
                            ),
                            error: false,
                            isDirty: false,
                          },
                          timescales: {
                            value: c.MetaData.Timescales,
                            error: false,
                            isDirty: false,
                          },
                          inputs: {
                            value: [],
                            error: false,
                            isDirty: false,
                          },
                        }
                      : {}),
                    dimensionsList: {
                      value: map(dimensionsList, (c1, index) => {
                        const data = find(
                          c.MetaData.DimensionMapping,
                          (j) => j.sourceDimension === index
                        );
                        return {
                          sourceDimensionId: c1.id,
                          sourceDimensionName: c1.fieldValue,
                          dimension: !isUndefined(
                            find(dimensions, {
                              DimNumber: get(data, 'dimension'),
                            })
                          )
                            ? find(dimensions, {
                                DimNumber: data.dimension,
                              })
                            : {
                                id: `unmappedDimension-${index}`,
                                Name: 'Unmapped',
                              },
                          dimensionInstances: {
                            value: filter(
                              filter(
                                dimensionInstances,
                                (item) =>
                                  item.DimNumber ===
                                  get(
                                    find(dimensions, {
                                      DimNumber: get(data, 'dimension'),
                                    }) as RowIdentifier,
                                    'DimNumber'
                                  )
                              ),
                              (item) =>
                                includes(data.dimensionInstances, item.Name)
                            ),
                            error: false,
                          },
                        };
                      }),
                      isDirty: false,
                    },
                  },
                };
              },
              {}
            );

            setState({
              isLoading: false,
              buttonType,
              saveDirtyDataCut: false,
              dimensionsList,
              dimensionIdsList: map(head(result), 'id'),
              originalDimensionsList: dimensionsList,
              onFinalizeDimensions:
                result[2].Finalized ===
                PortfolioDataSourceDataFinalizedFlag.Finalized,
              dataCutButtonsList: map(result[1], (item, index) => {
                return {
                  id: item.id,
                  isDirty: false,
                  name: `dataCut-${index + 1}`,
                  title: item.Name,
                };
              }),
              dataCutList,
              originalDataCutList: dataCutList,
            });
          }
        }
      );
    } catch (e) {
      enqueueSnackbar((e as any).body.message, {
        variant: 'error',
      });
    }
  };

  const navigateWithoutSaving = async (): Promise<void> => {
    const destination = state.navigateButton;
    const dataCutListLength = keys(state.dataCutList).length;

    if (destination === 'new') {
      setState({
        dataCutButtonsList: state.dataCutButtonsList.concat({
          id: dataCutListLength + 1,
          name: `dataCut-${dataCutListLength + 1}`,
          title: `DataCut - ${dataCutListLength + 1}`,
          isDirty: true,
        }),
        dataCutList: {
          ...state.dataCutList,
          ...state.originalDataCutList,
          [`dataCut-${dataCutListLength + 1}`]: {
            ...dataCutFieldsList,
            dimensionsList: {
              value: map(state.dimensionsList, (i, index) => {
                return {
                  sourceDimensionId: i.id,
                  sourceDimensionName: i.originalFieldValue,
                  dimension: {
                    id: `unmappedDimension-${index}`,
                    Name: 'Unmapped',
                  },
                  dimensionInstances: {
                    value: [],
                    error: false,
                  },
                };
              }),
              isDirty: false,
            },
          },
        },
        buttonType: `dataCut-${dataCutListLength + 1}`,
        saveDirtyDataCut: false,
      });
    } else {
      setState({
        dataCutButtonsList: state.dataCutButtonsList,
        dataCutList: {
          ...state.dataCutList,
          ...state.originalDataCutList,
        },
        buttonType: destination,
        saveDirtyDataCut: false,
      });
    }
  };

  const isAnyFieldUpdated = useMemo(() => {
    const dataCutList = state.dataCutList[state.buttonType];
    return some(
      compact(
        map(values(dataCutList), (item) => {
          if (isObject(item)) {
            return item.isDirty;
          }
        })
      ),
      (item) => item
    );
  }, [state.dataCutList, state.buttonType]);

  useEffect(() => {
    props.isThereAnUnsavedComponent(isAnyFieldUpdated);

    if (isAnyFieldUpdated) {
      window.onbeforeunload = () => true;
    } else {
      window.onbeforeunload = () => null;
    }
  }, [isAnyFieldUpdated]);

  usePrompt(ConfirmationMessageForUnSavedChanges, isAnyFieldUpdated);

  return (
    <>
      <DeleteDataCutDialog
        isOpen={state.isDeleteDataCutModalOpen}
        onClose={() =>
          setState({
            isDeleteDataCutModalOpen: false,
            deleteDataCutObject: undefined,
          })
        }
        dataCut={state.deleteDataCutObject as IDataCutFieldsList}
        refreshData={() => {
          props.refreshReports();
          refreshData();
          enqueueSnackbar('Datacut deleted successfully.', {
            variant: 'success',
          });
        }}
        selectedReport={props.selectedReport}
      />
      <DeleteDimensionDialog
        isOpen={state.isDeleteDimensionModalOpen}
        onClose={(e) => {
          if (e) {
            refreshData();
            enqueueSnackbar(
              `${state.deleteDimensionObject?.originalFieldValue} dimension deleted successfully.`,
              {
                variant: 'success',
              }
            );
          }
          setState({
            isDeleteDimensionModalOpen: false,
            deleteDimensionObject: undefined,
          });
        }}
        dimension={state.deleteDimensionObject as IDimensionsList}
        selectedReport={props.selectedReport}
        dataSourceID={props.selectedReportDataSourceData?.id}
      />
      <FinalizeDimensionDialog
        refreshData={async () => {
          enqueueSnackbar('Dimensions finalized successfully.', {
            variant: 'success',
          });
          setState({
            onFinalizeDimensions: true,
            isFinalizeDimensionsModalOpen: false,
          });
          props.refreshReports();
          props.refreshDataSource(props.selectedReportDataSourceData?.id);
          await refreshData();
        }}
        dimensionsList={state.dimensionsList}
        dataSourceID={props.selectedReportDataSourceData?.id}
        isOpen={state.isFinalizeDimensionsModalOpen}
        onClose={() => setState({ isFinalizeDimensionsModalOpen: false })}
      />
      <RenameDataCutDialog
        isOpen={state.isRenameDataCutModalOpen}
        onClose={() =>
          setState({
            isRenameDataCutModalOpen: false,
            renameDataCutObject: undefined,
          })
        }
        Name={get(
          find(state.dataCutButtonsList, {
            name: state.buttonType,
          }),
          'title',
          ''
        )}
        dataCut={state.renameDataCutObject as IDataCutFieldsList}
        refreshData={async () => {
          const res: Record<number, string> = reduce(
            entries(state.dataCutList),
            // eslint-disable-next-line no-sequences
            (acc, [key, value]) => ((acc[value.id!] = key), acc),
            {} as Record<number, string>
          );
          await refreshData(
            res[(state.renameDataCutObject as IDataCutFieldsList).id as number]
          );
          enqueueSnackbar('Datacut renamed successfully.', {
            variant: 'success',
          });
        }}
        dimensionsList={state.dimensionsList}
        selectedReport={props.selectedReport}
        handleChangeRenameDataCut={handleChangeRenameDataCut}
        datacutListTitles={map(state.dataCutButtonsList, 'title')}
        errorMessage={(e: string) =>
          enqueueSnackbar(e, {
            variant: 'error',
          })
        }
      />
      <ConditionalWrap
        condition={props.showComponentInADialog}
        wrap={(children) => (
          <PopupDialog
            hideDialogActions
            title="Portfolio Source Type"
            maxWidth={width > 1600 ? 'lg' : 'xl'}
            open={Boolean(props.isOpen)}
            close={() => {
              if (
                isAnyFieldUpdated &&
                !window.confirm(ConfirmationMessageForUnSavedChanges)
              ) {
                return;
              } else {
                setState(initialState);
                refreshDataSource();
                props.onClose && props.onClose();
              }
            }}
          >
            {children}
          </PopupDialog>
        )}
      >
        {state.isLoading ? (
          <CenteredSpinner />
        ) : state.isAccessDenied ? (
          <Alert severity="warning">
            No data to load. Please reach out to CloudCast support if you think
            you might be seeing this in error.
          </Alert>
        ) : (
          <Grid container>
            <Grid md={4}>
              <Grid container>
                <List sx={{ width: '100%' }}>
                  <ListItem disablePadding>
                    <ListItemButton
                      onClick={() =>
                        onHandleButtonTypeChange('sourceDimension')
                      }
                      selected={state.buttonType === 'sourceDimension'}
                    >
                      Source Dimensions
                    </ListItemButton>
                  </ListItem>
                </List>
              </Grid>
              <Divider />
              <Grid container>
                <List
                  sx={{ maxHeight: '300px', overflow: 'auto', width: '100%' }}
                >
                  {map(state.dataCutButtonsList, (item) => {
                    return (
                      <ListItem disablePadding key={item.id}>
                        <ListItemButton
                          divider
                          selected={state.buttonType === item.name}
                          onClick={() => onHandleButtonTypeChange(item.name)}
                        >
                          {item.title}
                        </ListItemButton>
                      </ListItem>
                    );
                  })}
                  <Box ref={dataCutButtonsListContainerRef} />
                </List>
                {state.onFinalizeDimensions && (
                  <>
                    <Divider />
                    <Button
                      variant="contained"
                      startIcon={<Add />}
                      onClick={addNewDataCut}
                      sx={{ mt: 2 }}
                    >
                      Create New Data Cut
                    </Button>
                  </>
                )}
              </Grid>
            </Grid>
            <Grid md={8} sx={{ px: 2 }}>
              {state.buttonType === 'sourceDimension' && (
                <>
                  {state.dimensionsList.length > 0 && (
                    <Grid container justifyContent="space-between">
                      <Grid md={6}>
                        <Typography variant="h5">Dimensions</Typography>
                      </Grid>
                      <Grid md={6} container justifyContent="flex-end">
                        {!state.onFinalizeDimensions && (
                          <Button
                            variant="contained"
                            onClick={onFinalizeDimensions}
                            disabled={
                              state.onSaveLoading ||
                              some(
                                state.dimensionsList,
                                (item) => item.editable
                              )
                            }
                          >
                            Finalize
                          </Button>
                        )}
                      </Grid>
                    </Grid>
                  )}
                  <Box sx={{ pt: 1 }}>
                    <Card>
                      <CardContent>
                        {map(state.dimensionsList, (item, index) => {
                          return (
                            <Box pb={2} key={item.id}>
                              <Grid container alignItems="center">
                                <Grid md={0.5}>
                                  <Avatar
                                    variant="square"
                                    sx={{ width: 24, height: 24 }}
                                  >
                                    {index + 1}
                                  </Avatar>
                                </Grid>
                                <Grid md={10}>
                                  <Input
                                    size="small"
                                    fullWidth
                                    readOnly={!get(item, 'editable')}
                                    onChange={(
                                      event: ChangeEvent<HTMLInputElement>
                                    ) =>
                                      onHandleDimensionInputChange(
                                        event,
                                        item.id
                                      )
                                    }
                                    value={
                                      get(item, 'editable')
                                        ? item.onChangeFieldValue
                                        : item.fieldValue
                                    }
                                    name={item.fieldName}
                                    inputRef={(ref) =>
                                      refs.set(item.id, ref as HTMLInputElement)
                                    }
                                  />
                                  {get(item, 'editable') && (
                                    <FormHelperText>
                                      {40 - item.onChangeFieldValue.length}{' '}
                                      characters left
                                    </FormHelperText>
                                  )}
                                </Grid>
                                {!get(item, 'editable') ? (
                                  <Grid md={0.5}>
                                    <IconButton
                                      onClick={() =>
                                        handleDimensionNameEdit(item.id)
                                      }
                                    >
                                      <Edit />
                                    </IconButton>
                                  </Grid>
                                ) : (
                                  <Grid md={1.5}>
                                    <Grid container>
                                      <IconButton
                                        onClick={() =>
                                          onSaveDimensionInputChange(item.id)
                                        }
                                      >
                                        <Check />
                                      </IconButton>
                                      <IconButton
                                        onClick={() =>
                                          onCancelDimensionInputChange(item.id)
                                        }
                                      >
                                        <Clear />
                                      </IconButton>
                                    </Grid>
                                  </Grid>
                                )}
                                {!state.onFinalizeDimensions &&
                                  !get(item, 'editable') && (
                                    <Grid md={0.5}>
                                      <IconButton
                                        onClick={() =>
                                          handleDimensionDelete(item.id)
                                        }
                                        color="error"
                                      >
                                        <Delete />
                                      </IconButton>
                                    </Grid>
                                  )}
                              </Grid>
                            </Box>
                          );
                        })}
                      </CardContent>
                    </Card>
                  </Box>
                  {!state.onFinalizeDimensions && (
                    <Button
                      sx={{ mt: 2 }}
                      variant="contained"
                      endIcon={<Add />}
                      onClick={addNewDimension}
                      disabled={
                        state.dimensionsList.length === MAX_DIMENSIONS ||
                        some(state.dimensionsList, (i) => !i.fieldValue)
                      }
                      title={
                        state.dimensionsList.length === MAX_DIMENSIONS
                          ? `You can create only a maximum of ${MAX_DIMENSIONS} dimensions`
                          : ''
                      }
                    >
                      Add Dimension
                    </Button>
                  )}
                </>
              )}
              {(() => {
                const dataCut = find(state.dataCutButtonsList, {
                  name: state.buttonType,
                });
                const dataCutList = state.dataCutList[state.buttonType];
                if (get(dataCut, 'name') === state.buttonType) {
                  return (
                    <>
                      {state.saveDirtyDataCut && (
                        <>
                          <Alert severity="warning">
                            You have an unsaved datacut. Proceeding further will
                            not save the current datacut. Do you want to
                            proceed?
                            <Grid
                              container
                              justifyContent="flex-end"
                              spacing={2}
                            >
                              <Grid>
                                <Button
                                  variant="text"
                                  onClick={() =>
                                    setState({
                                      saveDirtyDataCut: false,
                                    })
                                  }
                                >
                                  No
                                </Button>
                              </Grid>
                              <Grid>
                                <Button
                                  variant="contained"
                                  color="warning"
                                  onClick={navigateWithoutSaving}
                                >
                                  Yes
                                </Button>
                              </Grid>
                            </Grid>
                          </Alert>
                          <Box pb={2} />
                        </>
                      )}
                      <Grid
                        container
                        justifyContent="space-between"
                        alignItems="center"
                      >
                        <Grid md={8}>
                          <Typography variant="h5">
                            {get(dataCut, 'title')}
                          </Typography>
                        </Grid>
                        <Grid>
                          <Box>
                            <IconButton
                              onClick={() => handleRenameDataCut(dataCutList)}
                              title="Rename DataCut"
                            >
                              <Edit />
                            </IconButton>
                            <IconButton
                              onClick={() => handleDeleteDataCut(dataCutList)}
                              title="Delete DataCut"
                            >
                              <Delete color="error" />
                            </IconButton>
                            {(() => {
                              const isSaveDataCutButtonDisabled = !some(
                                compact(
                                  map(values(dataCutList), (item) => {
                                    if (isObject(item)) {
                                      return item.isDirty;
                                    }
                                  })
                                ),
                                (item) => item
                              );

                              const areAllScenariosAvailable =
                                dataCutList.scenarios.value.length !==
                                Number(dataCutList.numScenarios.value) ? false : true;

                              return (
                                <LoadingButton
                                  variant="contained"
                                  onClick={handleSaveDataCut}
                                  title="Save DataCut"
                                  disabled={isSaveDataCutButtonDisabled || !areAllScenariosAvailable}
                                  loading={state.onSaveLoading}
                                >
                                  Save
                                </LoadingButton>
                              );
                            })()}
                          </Box>
                        </Grid>
                      </Grid>
                      <Divider />
                      <Grid container alignItems="center" sx={{ my: 2 }}>
                        <Grid md={4}>
                          <InputLabel required>Model</InputLabel>
                        </Grid>
                        <Grid md={8}>
                          <SelectModel
                            clientId={props.clientId}
                            onChange={(modelId) => {
                              const modelChange = () =>
                                setState({
                                  dataCutList: {
                                    ...state.dataCutList,
                                    [state.buttonType]: {
                                      ...state.dataCutList[state.buttonType],
                                      ...dataCutFieldsList,
                                      Name: state.dataCutList[state.buttonType]
                                        .Name,
                                      modelId: {
                                        value: modelId as number,
                                        error: false,
                                        isDirty: !isEmpty(
                                          state.originalDataCutList[
                                            state.buttonType
                                          ]
                                        )
                                          ? !isEqual(
                                              state.originalDataCutList[
                                                state.buttonType
                                              ].modelId.value,
                                              modelId as number
                                            )
                                          : true,
                                      },
                                      dimensionsList: {
                                        value: map(
                                          state.dimensionsList,
                                          (i, index) => {
                                            return {
                                              sourceDimensionId: i.id,
                                              sourceDimensionName:
                                                i.originalFieldValue,
                                              dimension: {
                                                id: `unmappedDimension-${index}`,
                                                Name: 'Unmapped',
                                              },
                                              dimensionInstances: {
                                                value: [],
                                                error: false,
                                              },
                                            };
                                          }
                                        ),
                                        isDirty: false,
                                      },
                                    },
                                  },
                                });
                              if (state.dataCutList[state.buttonType].id) {
                                let confirmModelChange = window.confirm(
                                  'Changing the selected model will clear the rest of the datacut definition. Do you want to proceed?'
                                );
                                if (confirmModelChange) {
                                  modelChange();
                                }
                              } else {
                                modelChange();
                              }
                            }}
                            modelId={dataCutList.modelId.value}
                            size="small"
                            hasError={dataCutList.modelId.error}
                          />
                          {dataCutList.modelId.error && (
                            <FormHelperText error={dataCutList.modelId.error}>
                              Model is required
                            </FormHelperText>
                          )}
                        </Grid>
                      </Grid>
                      <Grid container alignItems="center" sx={{ mb: 2 }}>
                        <Grid md={4}>
                          <InputLabel required>Model Instance</InputLabel>
                        </Grid>
                        <Grid md={8}>
                          <SelectModelInstance
                            showOnlyPublished
                            clientId={props.clientId as number}
                            modelInstanceId={dataCutList.modelInstanceId.value}
                            onChange={(modelInstanceId) => {
                              const modelInstanceChange = () =>
                                setState({
                                  dataCutList: {
                                    ...state.dataCutList,
                                    [state.buttonType]: {
                                      ...state.dataCutList[state.buttonType],
                                      ...dataCutFieldsList,
                                      Name: state.dataCutList[state.buttonType]
                                        .Name,
                                      modelId:
                                        state.dataCutList[state.buttonType]
                                          .modelId,
                                      modelInstanceId: {
                                        value: modelInstanceId as number,
                                        error: false,
                                        isDirty: !isEmpty(
                                          state.originalDataCutList[
                                            state.buttonType
                                          ]
                                        )
                                          ? !isEqual(
                                              state.originalDataCutList[
                                                state.buttonType
                                              ].modelInstanceId.value,
                                              modelInstanceId as number
                                            )
                                          : true,
                                      },
                                      dimensionsList: {
                                        value: map(
                                          state.dimensionsList,
                                          (i, index) => {
                                            return {
                                              sourceDimensionId: i.id,
                                              sourceDimensionName:
                                                i.originalFieldValue,
                                              dimension: {
                                                id: `unmappedDimension-${index}`,
                                                Name: 'Unmapped',
                                              },
                                              dimensionInstances: {
                                                value: [],
                                                error: false,
                                              },
                                            };
                                          }
                                        ),
                                        isDirty: false,
                                      },
                                    },
                                  },
                                });
                              if (
                                state.dataCutList[state.buttonType].id &&
                                dataCutList.modelInstanceId.value
                              ) {
                                let confirmModelInstanceChange = window.confirm(
                                  'Changing the selected model instance will clear the rest of the datacut definition. Do you want to proceed?'
                                );
                                if (confirmModelInstanceChange) {
                                  modelInstanceChange();
                                }
                              } else {
                                modelInstanceChange();
                              }
                            }}
                            modelId={dataCutList.modelId.value}
                            size="small"
                            hasError={dataCutList.modelInstanceId.error}
                            btnStyle={selectBtnStyle}
                          />
                          {dataCutList.modelInstanceId.error && (
                            <FormHelperText
                              error={dataCutList.modelInstanceId.error}
                            >
                              Model Instance is required
                            </FormHelperText>
                          )}
                        </Grid>
                      </Grid>
                      {/*{dataCutList.modelInstanceId.value && (*/}
                      {/*  <Row className="align-items-center mb-2">*/}
                      {/*    <Col md={4}>*/}
                      {/*      <Label className="mb-0">Model Instance Lock</Label>*/}
                      {/*    </Col>*/}
                      {/*    <Col md={8}>*/}
                      {/*      <Checkbox*/}
                      {/*        disabled*/}
                      {/*        className="mb-0"*/}
                      {/*        checked={dataCutList.modelInstanceLock.value}*/}
                      {/*        onChange={(e: FormEvent<HTMLInputElement>) => {*/}
                      {/*          setState({*/}
                      {/*            dataCutList: {*/}
                      {/*              ...state.dataCutList,*/}
                      {/*              [state.buttonType]: {*/}
                      {/*                ...state.dataCutList[state.buttonType],*/}
                      {/*                modelInstanceLock: {*/}
                      {/*                  value: e.currentTarget.checked,*/}
                      {/*                  isDirty: !isEmpty(*/}
                      {/*                    state.originalDataCutList[*/}
                      {/*                      state.buttonType*/}
                      {/*                    ]*/}
                      {/*                  )*/}
                      {/*                    ? !isEqual(*/}
                      {/*                        state.originalDataCutList[*/}
                      {/*                          state.buttonType*/}
                      {/*                        ].modelInstanceLock.value,*/}
                      {/*                        e.currentTarget.checked*/}
                      {/*                      )*/}
                      {/*                    : true,*/}
                      {/*                },*/}
                      {/*              },*/}
                      {/*            },*/}
                      {/*          });*/}
                      {/*        }}*/}
                      {/*      />*/}
                      {/*    </Col>*/}
                      {/*  </Row>*/}
                      {/*)}*/}
                      <Grid container alignItems="center" mb={2}>
                        <Grid md={4}>
                          <InputLabel required># Scenarios</InputLabel>
                        </Grid>
                        <Grid md={8}>
                          <TextField
                            label=""
                            InputLabelProps={{ shrink: true }}
                            type="number"
                            size="small"
                            inputProps={{
                              inputMode: 'numeric',
                              pattern: '[0-9]*',
                              min: isEmpty(dataCutList.scenarios.value)
                                ? 1
                                : dataCutList.scenarios.value.length,
                              max: portfolioNumScenarios,
                            }}
                            placeholder="Select number of scenarios"
                            value={dataCutList.numScenarios.value}
                            onChange={onNumScenariosValueChange}
                            disabled={data.modelInstance === undefined}
                            fullWidth
                            onFocus={() =>
                              setState({
                                onFocusedInput: 'numScenarios',
                              })
                            }
                            onBlur={() =>
                              setState({
                                onFocusedInput: '',
                              })
                            }
                            error={dataCutList.numScenarios.error}
                            helperText={(() => {
                              if (state.onFocusedInput === 'numScenarios') {
                                return `You can input a maximum value of ${portfolioNumScenarios}`;
                              }
                              if (dataCutList.numScenarios.error) {
                                return '# Scenarios is required';
                              }
                            })()}
                          />
                        </Grid>
                      </Grid>
                      <Grid container alignItems="center" mb={2}>
                        <Grid md={4}>
                          <InputLabel required>Scenarios</InputLabel>
                        </Grid>
                        <Grid md={8}>
                          <ScenariosMultiSelect
                            showArchivedScenarios={false}
                            modelInstanceId={data.modelInstance?.id}
                            scenariosList={sortBy(data.scenarios || [], [
                              'Name',
                              'asc',
                            ])}
                            preSelectedScenarios={[
                              ...sortBy(
                                filter(data.scenarios || [], (scenario) =>
                                  some(
                                    dataCutList.scenarios.value,
                                    (selectedScenario) =>
                                      selectedScenario.id === scenario.id
                                  )
                                ),
                                ['Name', 'asc']
                              ),
                            ]}
                            handleScenarioChange={handleChangeScenarios}
                            maxSelection={dataCutList.numScenarios.value}
                            hasError={dataCutList.scenarios.error}
                            size="small"
                          />
                          {dataCutList.scenarios.error &&
                            dataCutList.rangeType.value === 'equal' && (
                              <FormHelperText
                                error={
                                  dataCutList.scenarios.error &&
                                  dataCutList.rangeType.value === 'equal'
                                }
                              >
                                You need to select exactly{' '}
                                {dataCutList.numScenarios.value} scenarios as
                                you have selected 'Exactly Equal' in Scenarios
                                Type.
                              </FormHelperText>
                            )}
                          {dataCutList.scenarios.error &&
                            dataCutList.rangeType.value === 'max' && (
                              <FormHelperText
                                error={
                                  dataCutList.scenarios.error &&
                                  dataCutList.rangeType.value === 'max'
                                }
                              >
                                The number of scenarios selected should be less
                                than or equal to the number in '# scenarios' for
                                'Maximum' Scenarios type
                              </FormHelperText>
                            )}
                        </Grid>
                      </Grid>
                      {/*{!isEmpty(dataCutList.scenarios) && (*/}
                      {/*  <Row className="align-items-center mb-2">*/}
                      {/*    <Col md={4}>*/}
                      {/*      <Label className="mb-0">Scenario Lock</Label>*/}
                      {/*    </Col>*/}
                      {/*    <Col md={8}>*/}
                      {/*      <Checkbox*/}
                      {/*        disabled*/}
                      {/*        className="mb-0"*/}
                      {/*        checked={dataCutList.scenariosLock.value}*/}
                      {/*        onChange={(e: FormEvent<HTMLInputElement>) => {*/}
                      {/*          setState({*/}
                      {/*            dataCutList: {*/}
                      {/*              ...state.dataCutList,*/}
                      {/*              [state.buttonType]: {*/}
                      {/*                ...state.dataCutList[state.buttonType],*/}
                      {/*                scenariosLock: {*/}
                      {/*                  value: e.currentTarget.checked,*/}
                      {/*                  isDirty: !isEmpty(*/}
                      {/*                    state.originalDataCutList[*/}
                      {/*                      state.buttonType*/}
                      {/*                    ]*/}
                      {/*                  )*/}
                      {/*                    ? !isEqual(*/}
                      {/*                        state.originalDataCutList[*/}
                      {/*                          state.buttonType*/}
                      {/*                        ].scenariosLock.value,*/}
                      {/*                        e.currentTarget.checked*/}
                      {/*                      )*/}
                      {/*                    : true,*/}
                      {/*                },*/}
                      {/*              },*/}
                      {/*            },*/}
                      {/*          });*/}
                      {/*        }}*/}
                      {/*      />*/}
                      {/*    </Col>*/}
                      {/*  </Row>*/}
                      {/*)}*/}
                      <Grid container alignItems="center" mb={2}>
                        <Grid md={4}>
                          <InputLabel required>Cut Type</InputLabel>
                        </Grid>
                        <Grid md={8}>
                          <FormControl fullWidth>
                            <Select
                              displayEmpty
                              size="small"
                              value={dataCutList.cutType.value}
                              onChange={(e) => {
                                setState({
                                  dataCutList: {
                                    ...state.dataCutList,
                                    [state.buttonType]: {
                                      ...state.dataCutList[state.buttonType],
                                      cutType: {
                                        value: e.target.value as Source,
                                        error: false,
                                        isDirty: !isEmpty(
                                          state.originalDataCutList[
                                            state.buttonType
                                          ]
                                        )
                                          ? !isEqual(
                                              state.originalDataCutList[
                                                state.buttonType
                                              ].cutType.value,
                                              e.target.value as Source
                                            )
                                          : true,
                                      },
                                      ...(e.target.value === 'inputs'
                                        ? {
                                            outputs: {
                                              value: [],
                                              error: false,
                                              isDirty: false,
                                            },
                                            timescales: {
                                              value: [],
                                              error: false,
                                              isDirty: false,
                                            },
                                          }
                                        : {
                                            inputs: {
                                              value: [],
                                              error: false,
                                              isDirty: false,
                                            },
                                          }),
                                    },
                                  },
                                });
                              }}
                            >
                              {map(dataSourceCutTypes, (item) => (
                                <MenuItem key={item} value={item}>
                                  {capitalize(item)}
                                </MenuItem>
                              ))}
                            </Select>
                            {dataCutList.cutType.error && (
                              <FormHelperText error={dataCutList.cutType.error}>
                                Cut Type is required
                              </FormHelperText>
                            )}
                          </FormControl>
                        </Grid>
                      </Grid>
                      {(() => {
                        if (dataCutList.cutType.value) {
                          if (dataCutList.cutType.value === 'outputs') {
                            return (
                              <>
                                <Grid container alignItems="center" mb={2}>
                                  <Grid md={4}>
                                    <InputLabel required>Outputs</InputLabel>
                                  </Grid>
                                  <Grid md={8}>
                                    <OutputsMultiSelect
                                      outputsList={filter(data.rowIdentifiers, [
                                        'Type',
                                        'Output',
                                      ]).sort(byName)}
                                      handleOutputChange={handleChangeOutputs}
                                      modelInstance={data.modelInstance}
                                      preSelectedOutputs={
                                        dataCutList.outputs.value
                                      }
                                      selectAll={true}
                                      size="small"
                                      hasError={dataCutList.outputs.error}
                                    />
                                    {dataCutList.outputs.error && (
                                      <FormHelperText
                                        error={dataCutList.outputs.error}
                                      >
                                        Output(s) is required
                                      </FormHelperText>
                                    )}
                                  </Grid>
                                </Grid>
                                {!isEmpty(data.modelInstance) && (
                                  <Grid container alignItems="center" mb={2}>
                                    <Grid md={4}>
                                      <InputLabel required>
                                        Timescales
                                      </InputLabel>
                                    </Grid>
                                    <Grid md={8}>
                                      <TimescalesMultiSelect
                                        timescalesList={getTimescalesForModelInstance(
                                          data.modelInstance as ModelInstance
                                        )}
                                        handleTimescaleChange={
                                          handleChangeTimescales
                                        }
                                        modelInstance={data.modelInstance}
                                        preSelectedTimescales={
                                          dataCutList.timescales.value
                                        }
                                        size="small"
                                        hasError={dataCutList.timescales.error}
                                      />
                                      {dataCutList.timescales.error && (
                                        <FormHelperText
                                          error={dataCutList.timescales.error}
                                        >
                                          Timescale(s) is required
                                        </FormHelperText>
                                      )}
                                    </Grid>
                                  </Grid>
                                )}
                              </>
                            );
                          }
                          return (
                            <Grid container alignItems="center" mb={2}>
                              <Grid md={4}>
                                <InputLabel required>Inputs</InputLabel>
                              </Grid>
                              <Grid md={8}>
                                <InputsMultiSelect
                                  inputsList={filter(data.rowIdentifiers, [
                                    'Type',
                                    'Input',
                                  ]).sort(byName)}
                                  selectAll={true}
                                  handleInputChange={handleChangeInputs}
                                  modelInstance={data.modelInstance}
                                  preSelectedInputs={dataCutList.inputs.value}
                                  size="small"
                                  hasError={dataCutList.inputs.error}
                                />
                                {dataCutList.inputs.error && (
                                  <FormHelperText
                                    error={dataCutList.inputs.error}
                                  >
                                    Input(s) is required
                                  </FormHelperText>
                                )}
                              </Grid>
                            </Grid>
                          );
                        }
                        return null;
                      })()}
                      {(() => {
                        const dimensions: RowIdentifier[] = sortBy(
                          filter(data.rowIdentifiers || [], [
                            'Type',
                            'Dimension',
                          ]),
                          ['DimNumber', 'asc']
                        );
                        const dimensionInstances: RowIdentifier[] = sortBy(
                          filter(data.rowIdentifiers || [], [
                            'Type',
                            'DimensionInstance',
                          ]),
                          ['DimNumber', 'asc']
                        );
                        return (
                          <Table size="small">
                            <TableHead>
                              <TableRow>
                                <TableCell>#</TableCell>
                                <TableCell>Report Dimensions</TableCell>
                                <TableCell>Model Dimension Mapping</TableCell>
                                <TableCell>Dimension Instance Filter</TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {map(
                                dataCutList.dimensionsList.value,
                                (dimension, index, array) => {
                                  return (
                                    <TableRow key={`dimension-${index}`}>
                                      <TableCell
                                        component="th"
                                        scope="row"
                                        id="index"
                                      >
                                        {index + 1}
                                      </TableCell>
                                      <TableCell id="sourceDimensions">
                                        {dimension.sourceDimensionName}
                                      </TableCell>
                                      <TableCell id="dataCutMapping">
                                        <FormControl fullWidth>
                                          <Select
                                            displayEmpty
                                            fullWidth
                                            disabled={isEmpty(
                                              data.rowIdentifiers
                                            )}
                                            size="small"
                                            value={
                                              get(
                                                dimension.dimension,
                                                'Name'
                                              ) || 'Unmapped'
                                            }
                                            onChange={(e) => {
                                              const selectedItem = find(
                                                dimensions,
                                                (i) => i.Name === e.target.value
                                              ) || {
                                                id: `unmappedDimension-${index}`,
                                                Name: 'Unmapped',
                                              };
                                              if (
                                                has(selectedItem, 'DimNumber')
                                              ) {
                                                const dimensionInstancesList =
                                                  filter(
                                                    dimensionInstances,
                                                    (dimensionInstance) => {
                                                      return (
                                                        (
                                                          selectedItem as RowIdentifier
                                                        ).DimNumber ===
                                                        dimensionInstance.DimNumber
                                                      );
                                                    }
                                                  );
                                                setState({
                                                  dataCutList: {
                                                    ...state.dataCutList,
                                                    [state.buttonType]: {
                                                      ...state.dataCutList[
                                                        state.buttonType
                                                      ],
                                                      dimensionsList: {
                                                        value: map(
                                                          array,
                                                          (item, index1) => {
                                                            if (
                                                              index1 === index
                                                            ) {
                                                              return {
                                                                ...item,
                                                                dimension:
                                                                  selectedItem as RowIdentifier,
                                                                dimensionInstances:
                                                                  {
                                                                    value:
                                                                      dimensionInstancesList,
                                                                    error:
                                                                      false,
                                                                  },
                                                              };
                                                            }
                                                            return item;
                                                          }
                                                        ),
                                                        isDirty: true,
                                                      },
                                                    },
                                                  },
                                                });
                                              } else {
                                                setState({
                                                  dataCutList: {
                                                    ...state.dataCutList,
                                                    [state.buttonType]: {
                                                      ...state.dataCutList[
                                                        state.buttonType
                                                      ],
                                                      dimensionsList: {
                                                        isDirty: true,
                                                        value: map(
                                                          array,
                                                          (item, index1) => {
                                                            if (
                                                              index1 === index
                                                            ) {
                                                              return {
                                                                ...item,
                                                                dimension:
                                                                  selectedItem as RowIdentifier,
                                                                dimensionInstances:
                                                                  {
                                                                    value: [],
                                                                    error:
                                                                      false,
                                                                  },
                                                              };
                                                            }
                                                            return item;
                                                          }
                                                        ),
                                                      },
                                                    },
                                                  },
                                                });
                                              }
                                            }}
                                          >
                                            {map(
                                              [
                                                ...dimensions,
                                                {
                                                  id: `unmappedDimension-${index}`,
                                                  Name: 'Unmapped',
                                                },
                                              ],
                                              (dimension) => {
                                                const dimensionNames = map(
                                                  map(
                                                    values(
                                                      state.dataCutList[
                                                        state.buttonType
                                                      ].dimensionsList.value
                                                    ),
                                                    'dimension'
                                                  ),
                                                  'Name'
                                                );
                                                return (
                                                  <MenuItem
                                                    key={dimension.id}
                                                    value={dimension.Name}
                                                    disabled={
                                                      dimension.Name !==
                                                      'Unmapped'
                                                        ? includes(
                                                            dimensionNames,
                                                            dimension.Name
                                                          )
                                                        : false
                                                    }
                                                    divider={
                                                      dimension.Name ===
                                                      'Unmapped'
                                                    }
                                                  >
                                                    {dimension.Name}
                                                  </MenuItem>
                                                );
                                              }
                                            )}
                                          </Select>
                                        </FormControl>
                                      </TableCell>
                                      <TableCell
                                        id="filter"
                                        sx={{
                                          width: '50%',
                                          maxWidth: '50%',
                                          overflow: 'hidden',
                                          textOverflow: 'ellipsis',
                                        }}
                                      >
                                        <DimensionInstancesMultiSelect
                                          handleDimensionInstanceChange={(
                                            selectedDimensionInstances
                                          ) =>
                                            handleDimensionInstanceChange(
                                              selectedDimensionInstances,
                                              index
                                            )
                                          }
                                          disabled={
                                            get(
                                              dimension.dimension,
                                              'Name',
                                              ''
                                            ) === 'Unmapped'
                                          }
                                          preSelectedDimensionInstances={
                                            dimension.dimensionInstances.value
                                          }
                                          dimensionInstances={
                                            get(
                                              dimension.dimension,
                                              'Name',
                                              ''
                                            ) === 'Unmapped'
                                              ? []
                                              : filter(
                                                  dimensionInstances,
                                                  (dimensionInstance) => {
                                                    return (
                                                      (
                                                        dimension.dimension as RowIdentifier
                                                      ).DimNumber ===
                                                      dimensionInstance.DimNumber
                                                    );
                                                  }
                                                )
                                          }
                                          modelInstance={data.modelInstance}
                                          size="small"
                                        />
                                      </TableCell>
                                    </TableRow>
                                  );
                                }
                              )}
                            </TableBody>
                            {state.allUnmappedDimensions && (
                              <TableFooter>
                                <TableRow>
                                  <TableCell
                                    colSpan={4}
                                    style={{ color: red[500] }}
                                  >
                                    There should be at least one mapped
                                    dimension to create a datacut.
                                  </TableCell>
                                </TableRow>
                              </TableFooter>
                            )}
                          </Table>
                        );
                      })()}
                    </>
                  );
                }
                return null;
              })()}
            </Grid>
          </Grid>
        )}
      </ConditionalWrap>
    </>
  );
};

export default EditPortfolioSourceType;
