import React, {
  ChangeEvent,
  Fragment,
  FunctionComponent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
} from 'react';
import {
  Box,
  Button,
  Card,
  CardContent,
  Chip,
  Container,
  Divider,
  FormControl,
  FormControlLabel,
  IconButton,
  List,
  ListSubheader,
  Radio,
  RadioGroup,
  Slide,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import {
  filter,
  find,
  flatMap,
  get,
  includes,
  isEmpty,
  map,
  orderBy,
  reject,
  some,
  toLower,
  trimStart,
  pick,
  values,
  compact,
  isObject,
  trimEnd,
  last,
  uniq,
  replace,
  split,
  isNull,
} from 'lodash';
import { useSearchParams } from 'react-router-dom';
import { DragIndicator, RemoveCircle } from '@mui/icons-material';

import {
  DragDropContext,
  Draggable,
  Droppable,
  DropResult,
} from 'react-beautiful-dnd';

import { grey } from '@mui/material/colors';

import { enqueueSnackbar } from 'notistack';

import { format } from 'date-fns';

import * as API from '../../../../../services/API';

import {
  Model,
  ModelInstance,
  RowIdentifier,
  Scenario,
  Timescale,
  XRefDate,
} from '../../../../../types/models';
import { useProfile } from '../../../../../hooks/useProfile';
import { parseId } from '../../../../../utils/parsing';
import useSetState from '../../../../../hooks/useSetState';
import { useData } from '../../../../../hooks/useData';
import SelectModel from '../../../../shared/SelectModel';
import SelectModelInstance from '../../../../shared/SelectModelInstance';
import SelectOutput from '../../../../Visualization/SelectOutput';
import SelectTimescale from '../../../../Visualization/SelectTimescale';

import SelectTimescaleValue, {
  ISelectTimescaleValueProps,
} from '../../../../Visualization/TimescaleValuePickers/SelectTimescaleValue';

import { removeDuplicateValuesById } from '../../../../../utils/misc';

import { IPortfolioReportStateData } from '../../../index';

import FromScenarios from './FromScenarios';
import ToScenarios from './ToScenarios';
import SelectInputsModal from './SelectInputsModal';
import WaterfallChartRangePicker from './WaterfallChartRangePicker';

export interface WaterfallColumns {
  id: number;
  name: string;
  inputs: RowIdentifier[];
  error: boolean;
}

interface IEditChartPanelProps {
  open: boolean;
  handleCloseEditChartPanel: () => void;
  handleSaveEditChartPanel: (data: {
    modelId: Model['id'];
    modelInstanceId: ModelInstance['id'];
    fromScenarioId: Scenario['id'];
    toScenarioId: Scenario['id'];
    outputId: RowIdentifier['id'];
    timescale: Timescale;
    chartType: string;
    timeScaleValue: ISelectTimescaleValueProps['value'] | string[] | null;
    columns: WaterfallColumns[];
    dimensionInstances: RowIdentifier[];
  }) => void;
  selectedModel?: Model['id'];
  selectedModelInstance?: ModelInstance['id'];
  selectedFromScenario?: Scenario['id'];
  selectedToScenario?: Scenario['id'];
  selectedOutput?: RowIdentifier['id'];
  selectedTimescale?: Timescale;
  selectedChartType?: string;
  selectedReport?: IPortfolioReportStateData;
  selectedTimeScaleValue: ISelectTimescaleValueProps['value'] | string[] | null;
  selectedColumns: WaterfallColumns[];
  selectedDimensionInstances: RowIdentifier[];
  isThereAnUnsavedComponent: (bool: boolean) => void;
  refresh: () => void;
}

interface IEditChartPanelState {
  modelId: {
    value?: Model['id'];
    isDirty: boolean;
  };
  modelInstanceId: {
    value?: ModelInstance['id'];
    isDirty: boolean;
  };
  fromScenarioId: {
    value?: Scenario['id'];
    isDirty: boolean;
  };
  toScenarioId: {
    value?: Scenario['id'];
    isDirty: boolean;
  };
  outputId: {
    value?: RowIdentifier['id'];
    isDirty: boolean;
  };
  timescale: {
    value?: Timescale;
    isDirty: boolean;
  };
  chartType: {
    value?: string;
    isDirty: boolean;
  };
  columns: {
    value: WaterfallColumns[];
    isDirty: boolean;
  };
  showInputsModal: boolean;
  showSegmentsModal: boolean;
  selectedColumnId?: number;
  timeScaleValue: {
    value?: any;
    isDirty: boolean;
  };
  dimensionInstances: {
    value: RowIdentifier[];
    isDirty: boolean;
  };
  dimensionInstanceSearchString: string;
  isSavingChartData: boolean;
  generatingChart: boolean;
}

const EditChartPanel: FunctionComponent<IEditChartPanelProps> = ({
  open,
  handleCloseEditChartPanel,
  handleSaveEditChartPanel,
  selectedModel,
  selectedModelInstance,
  selectedFromScenario,
  selectedToScenario,
  selectedOutput,
  selectedTimescale,
  selectedChartType,
  selectedReport,
  selectedTimeScaleValue,
  selectedDimensionInstances,
  selectedColumns,
  isThereAnUnsavedComponent,
  refresh,
}) => {
  const profile = useProfile();

  const [searchParams] = useSearchParams();

  const clientId =
    profile && profile.User.ClientID !== null
      ? profile.User.ClientID
      : parseId(searchParams.get('client'));

  const [state, setState] = useSetState<IEditChartPanelState>({
    modelId: {
      value: undefined,
      isDirty: false,
    },
    modelInstanceId: {
      value: undefined,
      isDirty: false,
    },
    fromScenarioId: {
      value: undefined,
      isDirty: false,
    },
    toScenarioId: {
      value: undefined,
      isDirty: false,
    },
    outputId: {
      value: undefined,
      isDirty: false,
    },
    timescale: {
      value: undefined,
      isDirty: false,
    },
    chartType: {
      value: undefined,
      isDirty: false,
    },
    columns: {
      value: [],
      isDirty: false,
    },
    showInputsModal: false,
    showSegmentsModal: false,
    dimensionInstances: {
      value: [],
      isDirty: false,
    },
    dimensionInstanceSearchString: '',
    timeScaleValue: {
      value: undefined,
      isDirty: false,
    },
    isSavingChartData: false,
    generatingChart: false,
  });

  const { data } = useData<{
    modelInstance?: ModelInstance;
    rowIdentifiers?: RowIdentifier[];
    scenarios?: Scenario[];
    dates?: XRefDate[];
  }>(
    () => ({
      modelInstance:
        clientId !== undefined && state.modelId.value !== undefined
          ? `/instances/${state.modelInstanceId.value}`
          : undefined,
      rowIdentifiers:
        state.modelInstanceId.value !== undefined
          ? `/instances/${state.modelInstanceId.value}/row_identifiers`
          : undefined,
      scenarios:
        state.modelInstanceId.value !== undefined
          ? `/instances/${state.modelInstanceId.value}/scenarios_load/finished`
          : undefined,
      dates: clientId !== undefined ? `/xref_date/${clientId}` : undefined,
    }),
    [state.modelInstanceId.value]
  );

  useEffect(() => {
    if (open) {
      setState({
        modelId: {
          value: selectedModel,
          isDirty: false,
        },
        modelInstanceId: {
          value: selectedModelInstance,
          isDirty: false,
        },
        fromScenarioId: {
          value: selectedFromScenario,
          isDirty: false,
        },
        toScenarioId: {
          value: selectedToScenario,
          isDirty: false,
        },
        outputId: {
          value: selectedOutput,
          isDirty: false,
        },
        timescale: {
          value: selectedTimescale,
          isDirty: false,
        },
        chartType: {
          value: selectedChartType,
          isDirty: false,
        },
        timeScaleValue: {
          value: selectedTimeScaleValue,
          isDirty: false,
        },
        columns: {
          value: selectedColumns,
          isDirty: false,
        },
        dimensionInstances: {
          value: selectedDimensionInstances,
          isDirty: false,
        },
        generatingChart: false,
      });
    }
  }, [
    open,
    selectedModel,
    selectedModelInstance,
    selectedFromScenario,
    selectedToScenario,
    selectedOutput,
    selectedTimescale,
    selectedChartType,
  ]);

  const outputs = useMemo(
    () => filter(data.rowIdentifiers, { Type: 'Output' }),
    [selectedModelInstance, data]
  );

  const dimensions = useMemo(
    () => filter(data.rowIdentifiers, { Type: 'Dimension' }),
    [selectedModelInstance, data]
  );

  const dimensionInstancesList = useMemo(
    () => filter(data.rowIdentifiers, { Type: 'DimensionInstance' }),
    [selectedModelInstance, data]
  );

  let isAnyFieldUpdated = useMemo(() => {
    const dataToBeChecked = pick(state, [
      'modelId',
      'modelInstanceId',
      'fromScenarioId',
      'toScenarioId',
      'outputId',
      'timescale',
      'chartType',
      'columns',
      'timeScaleValue',
      'dimensionInstances',
    ]);
    return some(
      compact(
        map(values(dataToBeChecked), (item) => {
          if (isObject(item)) {
            return item.isDirty;
          }
        })
      ),
      (item) => item
    );
  }, [state]);

  useEffect(() => {
    isThereAnUnsavedComponent(isAnyFieldUpdated);

    if (isAnyFieldUpdated) {
      window.onbeforeunload = () => true;
    } else {
      window.onbeforeunload = () => null;
    }
  }, [isAnyFieldUpdated]);

  const handleChangeModel = useCallback((id: Model['id']) => {
    setState({
      modelId: {
        value: id,
        isDirty: true,
      },
      modelInstanceId: {
        value: undefined,
        isDirty: false,
      },
      fromScenarioId: {
        value: undefined,
        isDirty: false,
      },
      toScenarioId: {
        value: undefined,
        isDirty: false,
      },
      outputId: {
        value: undefined,
        isDirty: false,
      },
      timescale: {
        value: undefined,
        isDirty: false,
      },
      chartType: {
        value: undefined,
        isDirty: false,
      },
    });
  }, []);

  const handleChangeModelInstance = useCallback((id: ModelInstance['id']) => {
    setState({
      modelInstanceId: {
        value: id,
        isDirty: true,
      },
      fromScenarioId: {
        value: undefined,
        isDirty: false,
      },
      toScenarioId: {
        value: undefined,
        isDirty: false,
      },
      outputId: {
        value: undefined,
        isDirty: false,
      },
      timescale: {
        value: undefined,
        isDirty: false,
      },
      chartType: {
        value: undefined,
        isDirty: false,
      },
    });
  }, []);

  const handleChangeOutput = useCallback((id: RowIdentifier['id']) => {
    setState({
      outputId: {
        value: id,
        isDirty: true,
      },
    });
  }, []);

  const handleChangeTimescale = useCallback(
    (timescale: Timescale) => {
      const startDateID = (data.modelInstance as ModelInstance).Start_DateID;
      const endDateID = (data.modelInstance as ModelInstance).End_DateID;
      const datesByTimescale = filter(data.dates, ['Timescale', timescale]);
      const startDate = find(data.dates, { DateID: startDateID });
      const endDate = find(data.dates, { DateID: endDateID });
      const minMaxRanges = (min: string, max: string): [number, number] => {
        switch (timescale) {
          case Timescale.Weekly:
            return [
              find(datesByTimescale, {
                Date: min,
              })?.DateID as number,
              find(datesByTimescale, {
                Date: max,
              })?.DateID as number,
            ];
          case Timescale.Monthly: {
            return [
              find(datesByTimescale, {
                Date: `${Number(format(new Date(min), 'M'))}/1/${Number(
                  format(new Date(min), 'yyyy')
                )}`,
              })?.DateID as number,
              find(datesByTimescale, {
                Date: `${Number(format(new Date(max), 'M'))}/1/${Number(
                  format(new Date(max), 'yyyy')
                )}`,
              })?.DateID as number,
            ];
          }
          case Timescale.Quarterly:
            return [
              find(datesByTimescale, {
                Date: `${Number(split(min, ' ')[0])} Q${Number(
                  replace(split(min, ' ')[1], /[^0-9]/g, '')
                )}`,
              })?.DateID as number,
              find(datesByTimescale, {
                Date: `${Number(split(max, ' ')[0])} Q${Number(
                  replace(split(max, ' ')[1], /[^0-9]/g, '')
                )}`,
              })?.DateID as number,
            ];
          case Timescale.Yearly: {
            return [
              find(datesByTimescale, {
                Date: format(new Date(min), 'yyyy'),
              })?.DateID as number,
              find(datesByTimescale, {
                Date: format(new Date(max), 'yyyy'),
              })?.DateID as number,
            ];
          }
        }
      };
      const minMaxDates = minMaxRanges(startDate!.Date, endDate!.Date);
      setState({
        timescale: {
          value: timescale,
          isDirty: true,
        },
        timeScaleValue: {
          value:
            state.chartType.value === 'pointintime'
              ? minMaxDates[0]
              : minMaxDates,
          isDirty: true,
        },
      });
    },
    [state.chartType.value, data]
  );

  const handleChangeChartType = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const startDateID = (data.modelInstance as ModelInstance).Start_DateID;
      const endDateID = (data.modelInstance as ModelInstance).End_DateID;
      const datesByTimescale = filter(data.dates, [
        'Timescale',
        state.timescale.value,
      ]);
      const startDate = find(data.dates, { DateID: startDateID });
      const endDate = find(data.dates, { DateID: endDateID });
      const minMaxRanges = (min: string, max: string) => {
        switch (state.timescale.value) {
          case Timescale.Weekly:
            return [
              find(datesByTimescale, {
                Date: min,
              })?.DateID as number,
              find(datesByTimescale, {
                Date: max,
              })?.DateID as number,
            ];
          case Timescale.Monthly: {
            return [
              find(datesByTimescale, {
                Date: `${Number(format(new Date(min), 'M'))}/1/${Number(
                  format(new Date(min), 'yyyy')
                )}`,
              })?.DateID as number,
              find(datesByTimescale, {
                Date: `${Number(format(new Date(max), 'M'))}/1/${Number(
                  format(new Date(max), 'yyyy')
                )}`,
              })?.DateID as number,
            ];
          }
          case Timescale.Quarterly:
            return [
              find(datesByTimescale, {
                Date: `${Number(split(min, ' ')[0])} Q${Number(
                  replace(split(min, ' ')[1], /[^0-9]/g, '')
                )}`,
              })?.DateID as number,
              find(datesByTimescale, {
                Date: `${Number(split(max, ' ')[0])} Q${Number(
                  replace(split(max, ' ')[1], /[^0-9]/g, '')
                )}`,
              })?.DateID as number,
            ];
          case Timescale.Yearly: {
            return [
              find(datesByTimescale, {
                Date: format(new Date(min), 'yyyy'),
              })?.DateID as number,
              find(datesByTimescale, {
                Date: format(new Date(max), 'yyyy'),
              })?.DateID as number,
            ];
          }
        }
      };
      const minMaxDates = minMaxRanges(startDate!.Date, endDate!.Date);
      setState({
        chartType: {
          value: (event.target as HTMLInputElement).value,
          isDirty: true,
        },
        timeScaleValue: {
          value:
            (event.target as HTMLInputElement).value === 'pointintime'
              ? minMaxDates![0]
              : minMaxDates,
          isDirty: true,
        },
      });
    },
    [state.chartType.value, data, state.timescale.value]
  );

  const waterfallColumnsRef = useRef<(HTMLDivElement | null)[]>([]);
  // you can access the elements with itemsRef.current[n]

  useEffect(() => {
    waterfallColumnsRef.current = waterfallColumnsRef.current.slice(
      0,
      state.columns.value.length
    );
  }, [state.columns]);

  useEffect(() => {
    const lastColumn = last(state.columns.value);
    if (lastColumn) {
      last(waterfallColumnsRef.current)?.scrollIntoView({ behavior: 'smooth' });
    }
  }, [state.columns]);

  const addNewColumn = useCallback(() => {
    setState({
      columns: {
        value: [
          ...state.columns.value,
          {
            id: state.columns.value.length + 1,
            name: '',
            inputs: [],
            error: false,
          },
        ],
        isDirty: true,
      },
    });
  }, [state]);

  const addInput = useCallback((id: number) => {
    setState({
      showInputsModal: true,
      selectedColumnId: id,
    });
  }, []);

  const onSaveInputs = useCallback(
    (inputs: RowIdentifier[]) => {
      setState({
        columns: {
          value: map(state.columns.value, (column) => {
            if (column.id === state.selectedColumnId) {
              return {
                ...column,
                inputs,
              };
            }
            return column;
          }),
          isDirty: true,
        },
        showInputsModal: undefined,
      });
    },
    [state]
  );

  const deleteInput = useCallback(
    (column: WaterfallColumns, input: RowIdentifier) => {
      setState({
        columns: {
          value: map(state.columns.value, (col) => {
            if (col.id === column.id) {
              return {
                ...col,
                inputs: reject(col.inputs, { id: input.id }),
              };
            }
            return col;
          }),
          isDirty: true,
        },
      });
    },
    [state]
  );

  const deleteColumn = useCallback(
    (column: WaterfallColumns) => {
      setState({
        columns: {
          value: reject(state.columns.value, { id: column.id }),
          isDirty: true,
        },
      });
    },
    [state]
  );

  const changeColumnName = useCallback(
      (
          column: WaterfallColumns,
          e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
      ) => {
        setState({
          columns: {
            value: map(state.columns.value, (col, idx, colArr) => {
              if (col.id === column.id) {
                const newName = trimStart(e.target.value.substring(0, 75));
                const lowerNewName: Lowercase<string> = toLower(newName) as Lowercase<string>;

                return {
                  ...col,
                  name: newName,
                  error: map(colArr, (i) => toLower(i.name)).includes(lowerNewName),
                };
              }
              return col;
            }),
            isDirty: true,
          },
        });
      },
      [state]
  );

  const onDragEnd = (result: DropResult) => {
    if (!result.destination) return;
    if (result.destination.index === result.source.index) return;

    const newTabs = Array.from(state.columns.value);
    const [removed] = newTabs.splice(result.source.index, 1);
    newTabs.splice(result.destination.index, 0, removed);
    setState({
      columns: {
        value: newTabs,
        isDirty: true,
      },
    });
  };

  const handleDimensionInstanceSearch = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      setState({ dimensionInstanceSearchString: trimStart(e.target.value) });
    },
    []
  );

  const areColumnsValid = () => {
    let allValid = false; // Assume all are valid to start

    for (let item of state.columns.value) {
      if (!item.name || item.inputs.length === 0) {
        allValid = false;
        break; // Exit loop if any invalid item is found
      } else {
        allValid = true;
      }
    }

    return allValid;
  };

  const filteredDimensionInstances = useMemo(() => {
    return filter(dimensionInstancesList, (dimensionInstance) =>
      includes(
        toLower(dimensionInstance.Name),
        toLower(state.dimensionInstanceSearchString)
      )
    );
  }, [state.dimensionInstanceSearchString, dimensionInstancesList]);

  const filteredDimensionInstancesDimNumbers = map(
    filteredDimensionInstances,
    'DimNumber'
  );

  const filteredDimensions = useMemo(() => {
    return orderBy(
      filter(dimensions, (dimension) => {
        return some(filteredDimensionInstancesDimNumbers, (dimNumber) => {
          return dimNumber === dimension.DimNumber;
        });
      }),
      ['DimNumber'],
      ['asc']
    );
  }, [filteredDimensionInstancesDimNumbers, dimensions]);

  const handleChangeDimensionInstances = useCallback(
    (dimensionInstance: RowIdentifier) => {
      setState({
        dimensionInstances: {
          value: removeDuplicateValuesById([
            ...state.dimensionInstances.value,
            dimensionInstance,
          ]),
          isDirty: true,
        },
      });
    },
    [state.dimensionInstances.value]
  );

  const saveReport = async () => {
    const rowIdentifiers = await API.load(
      `/instances/${state.modelInstanceId.value}/row_identifiers`
    );
    try {
      const result = await API.create(
        `/clients/${clientId}/portfolio_reports/${selectedReport?.id}`,
        {
          Name: selectedReport?.Name,
          Description: selectedReport?.Description,
          ModelID: state.modelId.value,
          ModelInstanceID: state.modelInstanceId.value,
          Metadata: {
            WaterfallChart: {
              Timescale: state.timescale.value,
              OutputName:
                find(rowIdentifiers, { id: state.outputId.value })?.Name || '',
              RowIdentifierIDs: map(state.dimensionInstances.value, 'id'),
              DateId_Start:
                state.chartType.value === 'pointintime'
                  ? state.timeScaleValue.value
                  : state.timeScaleValue.value[0],
              DateId_End:
                state.chartType.value === 'pointintime'
                  ? null
                  : state.timeScaleValue.value[1],
              ScenarioId_A: state.fromScenarioId.value,
              ScenarioId_B: state.toScenarioId.value,
            },
            WaterfallColumns: map(state.columns.value, (column, idx) => {
              return {
                Order: idx + 1,
                Name: trimEnd(column.name),
                InputNames: map(column.inputs, 'Name'),
              };
            }),
          },
        }
      );
      if (result) {
        enqueueSnackbar('Report saved successfully', { variant: 'success' });
        isThereAnUnsavedComponent(false);
        setState({ isSavingChartData: false });
        refresh();
      }
    } catch (error: any) {
      setState({ isSavingChartData: false });
      enqueueSnackbar(error.body.message, { variant: 'error' });
    }
  };

  return (
    <Box>
      <Slide direction="right" in={open} mountOnEnter unmountOnExit>
        <Container
          maxWidth={false}
          sx={{
            mb: 2,
            mt: 1,
          }}
        >
          <Grid
            container
            justifyContent="space-between"
            alignItems="center"
            sx={{ pb: 1 }}
          >
            <Grid xs={6}>
              <Typography variant="h6">
                Select Model and Model Instance
              </Typography>
            </Grid>
            <Grid
              xs={6}
              justifyContent="flex-end"
              container
              spacing={2}
              sx={{ my: 1 }}
            >
              <Button
                variant="outlined"
                sx={{ mr: 2 }}
                onClick={handleCloseEditChartPanel}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                sx={{ mr: 2 }}
                onClick={() => {
                  const columnNames = map(state.columns.value, (i) =>
                    toLower(i.name)
                  );
                  if (uniq(columnNames).length !== columnNames.length) {
                    enqueueSnackbar('Duplicate column name exists.', {
                      variant: 'error',
                    });
                  } else if (
                    state.modelId.value &&
                    state.modelInstanceId.value &&
                    state.fromScenarioId.value &&
                    state.toScenarioId.value &&
                    state.outputId.value &&
                    state.timescale.value &&
                    state.chartType.value &&
                    state.timeScaleValue.value &&
                    !isEmpty(state.columns.value) &&
                    areColumnsValid() &&
                    !isEmpty(state.dimensionInstances.value)
                  ) {
                    setState({ isSavingChartData: true });
                    saveReport();
                  } else {
                    enqueueSnackbar('Please fill all the required fields', {
                      variant: 'error',
                    });
                  }
                }}
                disabled={
                  !state.modelId.value ||
                  !state.modelInstanceId.value ||
                  !state.fromScenarioId.value ||
                  !state.toScenarioId.value ||
                  !state.outputId.value ||
                  !state.timescale.value ||
                  !state.chartType.value ||
                  isEmpty(state.columns.value) ||
                  isEmpty(state.dimensionInstances.value) ||
                  !areColumnsValid() ||
                  isNull(state.fromScenarioId.value) ||
                  isNull(state.toScenarioId.value) ||
                  state.isSavingChartData
                }
              >
                Save
              </Button>
              <Button
                variant="contained"
                onClick={() => {
                  const columnNames = map(state.columns.value, (i) =>
                    toLower(i.name)
                  );
                  if (uniq(columnNames).length !== columnNames.length) {
                    enqueueSnackbar('Duplicate column name exists.', {
                      variant: 'error',
                    });
                  } else if (
                    state.modelId.value &&
                    state.modelInstanceId.value &&
                    state.fromScenarioId.value &&
                    state.toScenarioId.value &&
                    state.outputId.value &&
                    state.timescale.value &&
                    state.chartType.value &&
                    state.timeScaleValue.value &&
                    !isEmpty(state.columns.value) &&
                    areColumnsValid() &&
                    !isEmpty(state.dimensionInstances.value)
                  ) {
                    setState({
                      generatingChart: true,
                    });
                    handleSaveEditChartPanel({
                      modelId: state.modelId.value,
                      modelInstanceId: state.modelInstanceId.value,
                      fromScenarioId: state.fromScenarioId.value,
                      toScenarioId: state.toScenarioId.value,
                      outputId: state.outputId.value as number,
                      timescale: state.timescale.value as Timescale,
                      chartType: state.chartType.value,
                      timeScaleValue: state.timeScaleValue.value,
                      columns: state.columns.value,
                      dimensionInstances: state.dimensionInstances.value,
                    });
                  } else {
                    enqueueSnackbar('Please fill all the required fields', {
                      variant: 'error',
                    });
                  }
                }}
                disabled={
                  !state.modelId.value ||
                  !state.modelInstanceId.value ||
                  !state.fromScenarioId.value ||
                  !state.toScenarioId.value ||
                  !state.outputId.value ||
                  !state.timescale.value ||
                  !state.chartType.value ||
                  isEmpty(state.columns.value) ||
                  isEmpty(state.dimensionInstances.value) ||
                  !areColumnsValid() ||
                  isNull(state.fromScenarioId.value) ||
                  isNull(state.toScenarioId.value) ||
                  state.isSavingChartData ||
                  state.generatingChart
                }
              >
                Generate Chart
              </Button>
            </Grid>
          </Grid>
          <Grid container spacing={2} sx={{ mb: 2 }}>
            <Grid sm={5}>
              <SelectModel
                size="small"
                onChange={handleChangeModel}
                clientId={clientId}
                modelId={state.modelId.value}
              />
            </Grid>
            <Grid sm={5}>
              <SelectModelInstance
                size="small"
                onChange={handleChangeModelInstance}
                modelId={state.modelId.value}
                modelInstanceId={state.modelInstanceId.value}
                clientId={clientId as number}
              />
            </Grid>
          </Grid>
          <Typography variant="h6">Select Chart Data</Typography>

          <Grid container spacing={2} sx={{ my: 2 }}>
            <Grid sm={5}>
              <FromScenarios
                large={false}
                modelInstanceId={
                  state.modelInstanceId.value as ModelInstance['id']
                }
                scenarioId={state.fromScenarioId.value}
                modelId={state.modelId.value as Model['id']}
                onChange={(scenarioId: number) =>
                  setState({
                    fromScenarioId: {
                      value: scenarioId,
                      isDirty: true,
                    },
                  })
                }
                disableScenarioId={state.toScenarioId.value}
              />
            </Grid>
            <Grid sm={5}>
              <ToScenarios
                large={false}
                modelInstanceId={
                  state.modelInstanceId.value as ModelInstance['id']
                }
                scenarioId={state.toScenarioId.value}
                modelId={state.modelId.value as Model['id']}
                onChange={(scenarioId: number) =>
                  setState({
                    toScenarioId: {
                      value: scenarioId,
                      isDirty: true,
                    },
                  })
                }
                disableScenarioId={state.fromScenarioId.value}
              />
            </Grid>
          </Grid>

          <Grid container spacing={2} sx={{ my: 2, alignItems: 'center' }}>
            <Grid sm={5}>
              <SelectOutput
                size="small"
                onChange={handleChangeOutput}
                outputsList={outputs}
                modelInstanceId={state.modelInstanceId.value}
                outputId={state.outputId.value}
              />
            </Grid>
            <Grid sm={2}>
              <SelectTimescale
                size="small"
                onChange={handleChangeTimescale}
                modelInstance={data.modelInstance}
                timescale={state.timescale.value}
              />
            </Grid>
            <Grid sm={2}>
              <FormControl>
                <RadioGroup
                  name="chartType"
                  value={state.chartType.value}
                  onChange={handleChangeChartType}
                >
                  <FormControlLabel
                    value="pointintime"
                    control={
                      <Radio
                        size="small"
                        disabled={!state.timescale.value}
                        checked={state.chartType.value === 'pointintime'}
                      />
                    }
                    label="Point In Time"
                  />
                  <FormControlLabel
                    value="cumulative"
                    control={
                      <Radio
                        size="small"
                        disabled={!state.timescale.value}
                        checked={state.chartType.value === 'cumulative'}
                      />
                    }
                    label="Cumulative"
                  />
                </RadioGroup>
              </FormControl>
            </Grid>
            <Grid sm={3}>
              {state.chartType.value === 'pointintime' &&
                data.modelInstance && (
                  <SelectTimescaleValue
                    modelInstance={data.modelInstance}
                    timescale={state.timescale.value}
                    size="small"
                    onChange={(timeScaleValue) => {
                      setState({
                        timeScaleValue: {
                          value: timeScaleValue,
                          isDirty: true,
                        },
                      });
                    }}
                    value={
                      state.timeScaleValue
                        .value as ISelectTimescaleValueProps['value']
                    }
                  />
                )}
              {state.chartType.value === 'cumulative' && data.modelInstance && (
                <WaterfallChartRangePicker
                  timescale={state.timescale.value as Timescale}
                  modelInstance={data.modelInstance as ModelInstance}
                  onHandleRangeChange={(startDate, endDate) => {
                    setState({
                      timeScaleValue: {
                        value: [startDate, endDate],
                        isDirty: true,
                      },
                    });
                  }}
                  value={state.timeScaleValue.value as any[]}
                />
              )}
            </Grid>
          </Grid>

          {!isEmpty(dimensions) && (
            <Grid container spacing={2}>
              <Grid xs={12}>
                <Typography variant="h6">
                  Select Segments (Dimensions)
                </Typography>
                <Grid
                  container
                  justifyContent="space-between"
                  alignItems="center"
                  sx={{ mt: 2 }}
                >
                  <Grid xs={6}>
                    <TextField
                      size="small"
                      label="Search"
                      onChange={handleDimensionInstanceSearch}
                      value={state.dimensionInstanceSearchString}
                    />
                  </Grid>
                </Grid>
                <List
                  sx={{
                    width: '100%',
                    position: 'relative',
                    overflow: 'auto',
                    maxHeight: 400,
                    '& ul': { padding: 0 },
                    my: 2,
                  }}
                  disablePadding
                  subheader={<li />}
                >
                  {map(filteredDimensions, (dimension) => (
                    <Fragment key={dimension.id}>
                      <Box component="li" id={dimension.Name}>
                        <Box component="ul" mb={2}>
                          <Typography
                            component={ListSubheader}
                            disableGutters
                            variant="subtitle1"
                            sx={{ backgroundColor: '#E8EDF3', px: 1, py: 0.5 }}
                          >
                            {dimension.Name}
                          </Typography>
                          {map(
                            state.dimensionInstanceSearchString
                              ? filter(dimensionInstancesList, (i) =>
                                  includes(
                                    toLower(i.Name),
                                    toLower(state.dimensionInstanceSearchString)
                                  )
                                )
                              : dimensionInstancesList,
                            (dimInstance) => {
                              if (
                                dimInstance.DimNumber === dimension.DimNumber
                              ) {
                                return (
                                  <Chip
                                    variant={
                                      includes(
                                        state.dimensionInstances.value,
                                        dimInstance
                                      )
                                        ? 'filled'
                                        : 'outlined'
                                    }
                                    onClick={() =>
                                      handleChangeDimensionInstances(
                                        dimInstance
                                      )
                                    }
                                    label={dimInstance.Name}
                                    sx={{
                                      color: (t) =>
                                        includes(
                                          map(
                                            state.dimensionInstances.value,
                                            'id'
                                          ),
                                          dimInstance.id
                                        )
                                          ? t.palette.secondary.main
                                          : t.palette.text.secondary,
                                      border: (t) =>
                                        includes(
                                          map(
                                            state.dimensionInstances.value,
                                            'id'
                                          ),
                                          dimInstance.id
                                        )
                                          ? `1px solid ${t.palette.secondary.main}`
                                          : `1px solid ${t.palette.divider}`,
                                      backgroundColor: (t) =>
                                        includes(
                                          map(
                                            state.dimensionInstances.value,
                                            'id'
                                          ),
                                          dimInstance.id
                                        )
                                          ? grey[100]
                                          : t.palette.background.paper,
                                      m: 1,
                                      my: 2,
                                    }}
                                    key={dimInstance.id}
                                  />
                                );
                              }
                              return null;
                            }
                          )}
                        </Box>
                        <Divider />
                      </Box>
                    </Fragment>
                  ))}
                </List>
              </Grid>
            </Grid>
          )}

          <Typography variant="h6">Define Waterfall Columns</Typography>
          <Button
            onClick={addNewColumn}
            disabled={
              state.columns.value.length === 10 || !state.modelInstanceId.value
            }
            sx={{ m: 1 }}
          >
            Add New Column
          </Button>
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="droppable" direction="horizontal">
              {(provided, snapshot) => (
                <div ref={provided.innerRef} {...provided.droppableProps}>
                  <Stack direction="row" spacing={2} sx={{ overflow: 'auto' }}>
                    {map(state.columns.value, (column, colIdx, columnArr) => (
                      <Draggable
                        key={column.id}
                        draggableId={column.id.toString()}
                        index={colIdx}
                      >
                        {(provided, snapshot) => {
                          return (
                            <Card
                              key={column.id}
                              sx={{ minWidth: '250px', maxWidth: '250px' }}
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                            >
                              <CardContent
                                ref={(el) =>
                                  (waterfallColumnsRef.current[colIdx] = el)
                                }
                                id={`${column.id}`}
                              >
                                <Stack
                                  direction="row"
                                  sx={{
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                  }}
                                  spacing={1}
                                >
                                  <DragIndicator />
                                  <TextField
                                    size="small"
                                    label="Column Name"
                                    value={column.name}
                                    onChange={(e) =>
                                      changeColumnName(column, e)
                                    }
                                    error={column.error}
                                    helperText={`${
                                      75 - column.name.length
                                    } characters left`}
                                  />
                                  <IconButton
                                    onClick={() => deleteColumn(column)}
                                  >
                                    <RemoveCircle />
                                  </IconButton>
                                </Stack>
                                <Button
                                  onClick={() => addInput(column.id)}
                                  disabled={!state.modelInstanceId.value}
                                  sx={{ m: 1 }}
                                >
                                  Add Input
                                </Button>
                                <Box>
                                  {map(column.inputs, (input) => (
                                    <Tooltip arrow title={input.Name}>
                                      <Chip
                                        sx={{ m: 0.5 }}
                                        key={input.id}
                                        label={input.Name}
                                        onDelete={() =>
                                          deleteInput(column, input)
                                        }
                                      />
                                    </Tooltip>
                                  ))}
                                </Box>
                                {/*<Divider />*/}
                                {/*<Button*/}
                                {/*  onClick={() => addSegments(column.id)}*/}
                                {/*  disabled={!state.modelInstanceId}*/}
                                {/*  sx={{ m: 1 }}*/}
                                {/*>*/}
                                {/*  Add Segments*/}
                                {/*</Button>*/}
                              </CardContent>
                            </Card>
                          );
                        }}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </Stack>
                </div>
              )}
            </Droppable>
          </DragDropContext>
          <SelectInputsModal
            isOpen={state.showInputsModal}
            onClose={() =>
              setState({
                showInputsModal: false,
              })
            }
            onSave={onSaveInputs}
            modelInstanceId={state.modelInstanceId.value}
            selectedInputs={get(
              find(state.columns.value, {
                id: state.selectedColumnId,
              }) as WaterfallColumns,
              'inputs',
              []
            )}
            disabledInputs={flatMap(
              reject(state.columns.value, { id: state.selectedColumnId }),
              'inputs'
            )}
          />
        </Container>
      </Slide>
    </Box>
  );
};

export default EditChartPanel;
