import React, {
  useState,
  useRef,
  forwardRef,
  useImperativeHandle,
  ChangeEvent,
  useEffect,
} from 'react';
import { TextField } from '@mui/material';

import { ICellEditorParams } from 'ag-grid-community';

import { IFormatParameters } from '../../../Visualization/formatParameters';

const getFormattedData = (num: string): string => {
  const len = num.split('.').length > 1 ? num.split('.')[1].length : 0;
  if (isNaN(Number(num))) {
    return num;
  }
  if (num === '') {
    return '';
  }
  if (Number(num) < 1 && Number(num) > -1) {
    return num;
  }
  if (len === 0) {
    if (Number(num) % 100 === 0) {
      return (Number(num) / 100).toFixed(0);
    } else if (Number(num) % 10 === 0) {
      return (Number(num) / 100).toFixed(1);
    } else {
      return (Number(num) / 100).toFixed(2);
    }
  }
  return (Number(num) / 100).toFixed(len > 0 ? len + 2 : 0);
};

export default forwardRef(
  (props: ICellEditorParams & { formatKey: IFormatParameters }, ref) => {
    const inputRef = useRef<HTMLInputElement>(null);
    const [value, setValue] = useState<string>(props.value);

    const inputHandler = (e: ChangeEvent<HTMLInputElement>): void => {
      setValue(e.target.value.replace(/[^0-9.-]+/, ''));
    };

    useEffect(() => {
      inputRef.current?.focus();
      inputRef.current?.select();
    }, []);

    useImperativeHandle(ref, () => {
      return {
        getValue: () => {
          return value ? getFormattedData(value) : '';
        },
        afterGuiAttached: () => {
          setValue(props.value);
          inputRef.current?.focus();
          inputRef.current?.select();
        },
      };
    });

    return (
      <TextField
        size="small"
        style={{ padding: '5px 0' }}
        type="text"
        inputRef={inputRef}
        onChange={inputHandler}
        value={value}
      />
    );
  }
);
