import React, {
  ChangeEvent,
  Fragment,
  FunctionComponent,
  useCallback,
  useEffect,
  useMemo,
} from 'react';
import {
  Box,
  Button,
  Chip,
  Container,
  Divider,
  FormControlLabel,
  List,
  ListSubheader,
  Slide,
  Switch,
  TextField,
  Typography,
} from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import {
  filter,
  find,
  includes,
  isEmpty,
  isUndefined,
  map,
  orderBy,
  some,
  toLower,
  trimStart,
} from 'lodash';

import { grey } from '@mui/material/colors';

import { useSearchParams } from 'react-router-dom';

import { useSnackbar } from 'notistack';

import SelectOutput from '../../../Visualization/SelectOutput';
import SelectTimescale from '../../../Visualization/SelectTimescale';
import { useData } from '../../../../hooks/useData';
import {
  Model,
  ModelInstance,
  RowIdentifier,
  Scenario,
  ScenarioArchiveFlag,
  Timescale,
} from '../../../../types/models';
import useSetState from '../../../../hooks/useSetState';
import SelectModel from '../../../shared/SelectModel';
import SelectModelInstance from '../../../shared/SelectModelInstance';
import ScenariosMultiSelect from '../../../Visualization/ScenariosMultiSelect';
import { removeDuplicateValuesById } from '../../../../utils/misc';
import { parseId } from '../../../../utils/parsing';
import { useProfile } from '../../../../hooks/useProfile';

const maxScenariosSelected: number = 3;

interface IEditChartPanelProps {
  showScenarios?: boolean;
  open: boolean;
  modelInstance?: ModelInstance;
  handleCloseEditChartPanel: () => void;
  handleSaveEditChartPanel: (data: {
    modelId?: Model['id'];
    modelInstanceId?: ModelInstance['id'];
    scenarios: Scenario['id'][];
    outputId: RowIdentifier['id'];
    timescale: Timescale;
    dimensionInstances: RowIdentifier[];
    aggregateFlag: boolean;
  }) => void;
  selectedOutput?: RowIdentifier['id'];
  selectedModel?: Model['id'];
  selectedModelInstance?: ModelInstance['id'];
  selectedScenarios: Scenario['id'][];
  selectedDimensionInstances: RowIdentifier[];
  selectedTimescale?: Timescale;
  aggregateOutputs: boolean;
  refreshState: boolean;
}

interface IEditChartPanelState {
  selectedDimension: RowIdentifier['Name'];
  dimensionInstanceSearchString: string;
  modelId?: Model['id'];
  modelInstanceId?: ModelInstance['id'];
  scenarios: Scenario['id'][];
  outputId?: RowIdentifier['id'];
  timescale?: Timescale;
  aggregateFlag: boolean;
  dimensionInstances: RowIdentifier[];
}

const EditChartPanel: FunctionComponent<IEditChartPanelProps> = ({
  open,
  modelInstance,
  handleCloseEditChartPanel,
  showScenarios = false,
  handleSaveEditChartPanel,
  selectedScenarios,
  selectedModelInstance,
  selectedModel,
  selectedOutput,
  selectedTimescale,
  selectedDimensionInstances,
  aggregateOutputs,
  refreshState,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const profile = useProfile();

  const [searchParams] = useSearchParams();

  const clientId =
    profile && profile.User.ClientID !== null
      ? profile.User.ClientID
      : parseId(searchParams.get('client'));

  const [state, setState] = useSetState<IEditChartPanelState>({
    selectedDimension: '',
    dimensionInstanceSearchString: '',
    modelId: searchParams.get('model')
      ? Number(searchParams.get('model'))
      : undefined,
    modelInstanceId: searchParams.get('instance')
      ? Number(searchParams.get('instance'))
      : undefined,
    scenarios: [],
    outputId: undefined,
    timescale: undefined,
    aggregateFlag: true,
    dimensionInstances: [],
  });

  const model = searchParams.get('model');
  const instance = searchParams.get('instance');

  const { data, refresh } = useData<{
    modelInstance?: ModelInstance;
    rowIdentifiers?: RowIdentifier[];
    scenarios?: Scenario[];
  }>(
    () => ({
      modelInstance:
        clientId !== undefined && state.modelId !== undefined
          ? `/instances/${state.modelInstanceId}`
          : undefined,
      rowIdentifiers:
        state.modelInstanceId !== undefined
          ? `/instances/${state.modelInstanceId}/row_identifiers`
          : undefined,
      scenarios:
        state.modelInstanceId !== undefined
          ? `/instances/${state.modelInstanceId}/scenarios_load/finished`
          : undefined,
    }),
    [state.modelInstanceId]
  );

  useEffect(() => {
    if (open) {
      setState(
        {
          modelId: selectedModel || (model ? Number(model) : undefined),
          modelInstanceId:
            selectedModelInstance || (instance ? Number(instance) : undefined),
          scenarios: selectedScenarios,
          outputId: selectedOutput,
          timescale: selectedTimescale,
          aggregateFlag: aggregateOutputs,
          dimensionInstances: selectedDimensionInstances,
        },
        () => {
          refresh();
        }
      );
    }
  }, [
    open,
    selectedModel,
    selectedModelInstance,
    selectedScenarios,
    selectedOutput,
    selectedTimescale,
    aggregateOutputs,
    selectedDimensionInstances,
    searchParams,
  ]);

  const outputs = useMemo(
    () => filter(data.rowIdentifiers, { Type: 'Output' }),
    [modelInstance, data]
  );

  const dimensions = useMemo(
    () => filter(data.rowIdentifiers, { Type: 'Dimension' }),
    [modelInstance, data]
  );

  const dimensionInstancesList = useMemo(
    () => filter(data.rowIdentifiers, { Type: 'DimensionInstance' }),
    [modelInstance, data]
  );

  const handleDimensionInstanceSearch = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      setState({ dimensionInstanceSearchString: trimStart(e.target.value) });
    },
    []
  );

  const filteredDimensionInstances = useMemo(() => {
    return filter(dimensionInstancesList, (dimensionInstance) =>
      includes(
        toLower(dimensionInstance.Name),
        toLower(state.dimensionInstanceSearchString)
      )
    );
  }, [state.dimensionInstanceSearchString, dimensionInstancesList]);

  const filteredDimensionInstancesDimNumbers = map(
    filteredDimensionInstances,
    'DimNumber'
  );

  const filteredDimensions = useMemo(() => {
    return orderBy(
      filter(dimensions, (dimension) => {
        return some(filteredDimensionInstancesDimNumbers, (dimNumber) => {
          return dimNumber === dimension.DimNumber;
        });
      }),
      ['DimNumber'],
      ['asc']
    );
  }, [filteredDimensionInstancesDimNumbers, dimensions]);

  const output = useMemo(() => {
    return find(outputs, { id: state.outputId }) as RowIdentifier;
  }, [outputs, state.outputId]);

  useEffect(() => {
    if (!isUndefined(output)) {
      setState({
        aggregateFlag: output.DisableOutputAggregation
          ? false
          : aggregateOutputs,
      });
    }
  }, [output]);

  const handleChangeModel = useCallback((id: Model['id']) => {
    setState({
      modelId: id,
      modelInstanceId: undefined,
      scenarios: [],
      outputId: undefined,
      timescale: undefined,
      dimensionInstances: [],
    });
  }, []);

  const handleChangeModelInstance = useCallback((id: ModelInstance['id']) => {
    setState({
      modelInstanceId: id,
      scenarios: [],
      outputId: undefined,
      timescale: undefined,
      dimensionInstances: [],
    });
  }, []);

  const handleChangeScenarios = (scenarios: Scenario[]): void => {
    if (scenarios.length > maxScenariosSelected) {
      enqueueSnackbar(
        ` You can only select a maximum of ${maxScenariosSelected} scenarios.`,
        { variant: 'error' }
      );
    } else {
      setState({
        scenarios: map(scenarios, 'id'),
      });
    }
  };

  const handleChangeOutput = useCallback((id: RowIdentifier['id']) => {
    setState({
      outputId: id,
    });
  }, []);

  const handleChangeTimescale = useCallback((timescale: Timescale) => {
    setState({
      timescale,
    });
  }, []);

  const handleChangeAggregation = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      setState({
        aggregateFlag: e.target.checked,
      });
    },
    []
  );

  const handleChangeDimensionInstances = useCallback(
    (dimensionInstance: RowIdentifier) => {
      setState({
        dimensionInstances: removeDuplicateValuesById([
          ...state.dimensionInstances,
          dimensionInstance,
        ]),
      });
    },
    [state.dimensionInstances]
  );

  const getScenarioList = useMemo(() => {
    return filter(data.scenarios, (item) => item.Archive_Flag === ScenarioArchiveFlag.Current); // filtering out scenarios which are not archived
  }, [data]);

  return (
    <Box>
      <Slide direction="right" in={open} mountOnEnter unmountOnExit>
        <Container
          maxWidth={false}
          sx={{
            mb: 2,
            mt: 1,
          }}
        >
          <Grid
            container
            justifyContent="space-between"
            alignItems="center"
            sx={{ pb: 1 }}
          >
            <Grid xs={6}>
              <Typography variant="h6">Select Chart Data</Typography>
            </Grid>
            <Grid
              xs={6}
              justifyContent="flex-end"
              container
              spacing={2}
              sx={{ my: 1 }}
            >
              {refreshState && (
                <Button
                  variant="outlined"
                  sx={{ mr: 2 }}
                  onClick={handleCloseEditChartPanel}
                >
                  Cancel
                </Button>
              )}
              <Button
                variant="contained"
                onClick={() =>
                  handleSaveEditChartPanel({
                    modelId: state.modelId,
                    modelInstanceId: state.modelInstanceId,
                    dimensionInstances: state.dimensionInstances,
                    scenarios: state.scenarios,
                    outputId: state.outputId as number,
                    timescale: state.timescale as Timescale,
                    aggregateFlag: state.aggregateFlag,
                  })
                }
                disabled={
                  !state.modelId ||
                  !state.modelInstanceId ||
                  isEmpty(state.scenarios) ||
                  !state.outputId ||
                  !state.timescale ||
                  isEmpty(state.dimensionInstances)
                }
              >
                {!refreshState ? 'Generate' : 'Update'}
              </Button>
            </Grid>
          </Grid>
          {showScenarios && (
            <Grid container spacing={2} sx={{ mb: 2 }} direction="column">
              <Grid sm={5}>
                <SelectModel
                  size="small"
                  onChange={handleChangeModel}
                  clientId={clientId}
                  modelId={state.modelId}
                />
              </Grid>
              <Grid sm={5}>
                <SelectModelInstance
                  size="small"
                  onChange={handleChangeModelInstance}
                  modelId={state.modelId}
                  modelInstanceId={state.modelInstanceId}
                  clientId={clientId as number}
                />
              </Grid>
              <Grid sm={5}>
                <ScenariosMultiSelect
                  size="small"
                  handleScenarioChange={handleChangeScenarios}
                  modelInstanceId={state.modelInstanceId}
                  scenariosList={getScenarioList}
                  preSelectedScenarios={filter(data.scenarios, (i) =>
                    includes(state.scenarios, i.id)
                  )}
                />
              </Grid>
            </Grid>
          )}
          <Typography variant="h6">Select Outputs</Typography>

          <Grid container spacing={2} sx={{ my: 2 }} direction="column">
            <Grid sm={5}>
              <SelectOutput
                size="small"
                onChange={handleChangeOutput}
                outputsList={outputs}
                modelInstanceId={state.modelInstanceId}
                outputId={state.outputId}
              />
            </Grid>
            <Grid sm={5}>
              <SelectTimescale
                size="small"
                onChange={handleChangeTimescale}
                modelInstance={data.modelInstance}
                timescale={state.timescale}
              />
            </Grid>
          </Grid>
          <Grid container sx={{ mb: 2 }}>
            <Grid xs={12}>
              <FormControlLabel
                control={
                  <Switch
                    checked={state.aggregateFlag}
                    onChange={handleChangeAggregation}
                    disabled={
                      (output && output!.DisableOutputAggregation) || false
                    }
                  />
                }
                label="Aggregate Outputs"
              />
            </Grid>
          </Grid>
          {!isEmpty(dimensions) && (
            <Grid container spacing={2}>
              <Grid xs={12}>
                <Typography variant="h6">
                  Select Segments (Dimensions)
                </Typography>
                <Grid
                  container
                  justifyContent="space-between"
                  alignItems="center"
                  sx={{ mt: 2 }}
                >
                  <Grid xs={6}>
                    <TextField
                      size="small"
                      label="Search"
                      onChange={handleDimensionInstanceSearch}
                      value={state.dimensionInstanceSearchString}
                    />
                  </Grid>
                </Grid>
                <List
                  sx={{
                    width: '100%',
                    position: 'relative',
                    overflow: 'auto',
                    maxHeight: 400,
                    '& ul': { padding: 0 },
                    my: 2,
                  }}
                  disablePadding
                  subheader={<li />}
                >
                  {map(filteredDimensions, (dimension) => (
                    <Fragment key={dimension.id}>
                      <Box component="li" id={dimension.Name}>
                        <Box component="ul" mb={2}>
                          <Typography
                            component={ListSubheader}
                            disableGutters
                            variant="subtitle1"
                            sx={{ backgroundColor: '#E8EDF3', px: 1, py: 0.5 }}
                          >
                            {dimension.Name}
                          </Typography>
                          {map(
                            state.dimensionInstanceSearchString
                              ? filter(dimensionInstancesList, (i) =>
                                  includes(
                                    toLower(i.Name),
                                    toLower(state.dimensionInstanceSearchString)
                                  )
                                )
                              : dimensionInstancesList,
                            (dimInstance) => {
                              if (
                                dimInstance.DimNumber === dimension.DimNumber
                              ) {
                                return (
                                  <Chip
                                    variant={
                                      includes(
                                        state.dimensionInstances,
                                        dimInstance
                                      )
                                        ? 'filled'
                                        : 'outlined'
                                    }
                                    onClick={() =>
                                      handleChangeDimensionInstances(
                                        dimInstance
                                      )
                                    }
                                    label={dimInstance.Name}
                                    sx={{
                                      color: (t) =>
                                        includes(
                                          map(state.dimensionInstances, 'id'),
                                          dimInstance.id
                                        )
                                          ? t.palette.secondary.main
                                          : t.palette.text.secondary,
                                      border: (t) =>
                                        includes(
                                          map(state.dimensionInstances, 'id'),
                                          dimInstance.id
                                        )
                                          ? `1px solid ${t.palette.secondary.main}`
                                          : `1px solid ${t.palette.divider}`,
                                      backgroundColor: (t) =>
                                        includes(
                                          map(state.dimensionInstances, 'id'),
                                          dimInstance.id
                                        )
                                          ? grey[100]
                                          : t.palette.background.paper,
                                      m: 1,
                                      my: 2,
                                    }}
                                    key={dimInstance.id}
                                  />
                                );
                              }
                              return null;
                            }
                          )}
                        </Box>
                        <Divider />
                      </Box>
                    </Fragment>
                  ))}
                </List>
              </Grid>
            </Grid>
          )}
        </Container>
      </Slide>
    </Box>
  );
};

export default EditChartPanel;
