import React, { PureComponent } from 'react';
import {
  // AutoSizer manages the list's width so it fills the available space
  AutoSizer,
  // CellMeasurer and CellMeasurerCache manage cell height so they can be expanded
  CellMeasurer,
  CellMeasurerCache,
  List,
  ListRowProps,
  // WindowScroller manages the list's height so you can scroll with the window
  WindowScroller,
} from 'react-virtualized';

import { Box } from '@mui/material';

import { Submission, GeneratedFile } from '../../types/models';
import * as API from '../../services/API';

import GenerateReportDialog from '../shared/GenerateReportDialog';

import SubmissionCard from './SubmissionCard';

interface Props {
  canSubmit: boolean;
  isActionRestricted: boolean;
  instanceId: number;
  submissions: Submission[];
  activeSubmissionIds: number[];
  groups?: { [id: number]: string };
  deleteSubmission: (submission: Submission) => void;
  onSubmissionRestore: (submission: Submission) => void;
  onSubmissionTransfer: (submission: Submission) => void;
}

interface State {
  expandedRows: { [id: number]: boolean };
  comparedSubmissionIDs: number[]; // maximum of two submissions to compare
  comparisonGroupRowIdentifierID?: number;
  isComparing: boolean;
  generatedFile?: GeneratedFile;
  comparisonError?: Error;
}

class SubmissionList extends PureComponent<Props, State> {
  cache: CellMeasurerCache;
  listRef?: List | null;

  constructor(props: Props) {
    super(props);

    this.state = {
      expandedRows: {},
      comparedSubmissionIDs: [],
      comparisonGroupRowIdentifierID: undefined,
      isComparing: false,
      generatedFile: undefined,
      comparisonError: undefined,
    };
    this.cache = new CellMeasurerCache({
      fixedWidth: true,
      defaultHeight: 100,
      keyMapper: (rowIndex) => this.props.submissions[rowIndex].id,
    });
  }

  componentDidUpdate(prevProps: Props) {
    if (prevProps.submissions !== this.props.submissions) {
      if (this.listRef) {
        this.listRef.recomputeRowHeights(0);
      }
    }
  }

  public renderRow = ({ index, key, style, parent }: ListRowProps) => {
    const {
      submissions,
      activeSubmissionIds,
      canSubmit,
      isActionRestricted,
      instanceId,
      groups,
      deleteSubmission,
      onSubmissionRestore,
      onSubmissionTransfer,
    } = this.props;
    const { comparedSubmissionIDs } = this.state;
    const submission = submissions[index];

    return (
      <CellMeasurer
        key={key}
        cache={this.cache}
        parent={parent}
        columnIndex={0}
        rowIndex={index}
      >
        <div style={style}>
          <Box mb={2}>
            <SubmissionCard
              instanceId={instanceId}
              submission={submission}
              active={activeSubmissionIds.includes(submission.id)}
              comparing={comparedSubmissionIDs.includes(submission.id)}
              canSubmit={canSubmit}
              isActionRestricted={isActionRestricted}
              dimensionGroup={
                groups && submission.GroupRowIdentifierID
                  ? groups[submission.GroupRowIdentifierID]
                  : undefined
              }
              onDelete={() => deleteSubmission(submission)}
              expanded={this.state.expandedRows[submission.id]}
              onExpand={this.onExpand}
              onRestore={onSubmissionRestore}
              onSubmissionTransferTrigger={onSubmissionTransfer}
              onCompare={this.onCompareSubmission}
              canCompare={this.canCompareSubmission(submission)}
              onLaunchComparison={this.launchComparison}
              canLaunchComparison={comparedSubmissionIDs.length === 2}
            />
          </Box>
        </div>
      </CellMeasurer>
    );
  };

  public render() {
    const { submissions } = this.props;
    const { isComparing, generatedFile, comparisonError } = this.state;

    return (
      <>
        <GenerateReportDialog
          isOpen={isComparing}
          error={comparisonError}
          onClose={this.closeComparisonDialog}
          generatedFile={generatedFile}
        />
        <AutoSizer disableHeight={true}>
          {({ width }) => (
            <WindowScroller>
              {({ height, isScrolling, onChildScroll, scrollTop }) => (
                <List
                  autoHeight={true}
                  height={height}
                  isScrolling={isScrolling}
                  onScroll={onChildScroll}
                  rowCount={submissions.length}
                  deferredMeasurementCache={this.cache}
                  rowHeight={this.cache.rowHeight}
                  rowRenderer={this.renderRow}
                  scrollTop={scrollTop}
                  width={width}
                  ref={(_ref) => {
                    this.listRef = _ref;
                  }}
                />
              )}
            </WindowScroller>
          )}
        </AutoSizer>
      </>
    );
  }

  private onExpand = (submissionId: number, expanded: boolean) => {
    this.setState(
      (oldState) => ({
        expandedRows: {
          ...oldState.expandedRows,
          [submissionId]: expanded,
        },
      }),
      () => {
        this.cache.clearAll();
        if (this.listRef) {
          this.listRef.recomputeRowHeights(0);
        }
      }
    );
  };

  private onCompareSubmission = (submission: Submission) => {
    const { comparedSubmissionIDs, comparisonGroupRowIdentifierID } =
      this.state;

    if (comparedSubmissionIDs.includes(submission.id)) {
      this.setState(
        {
          comparedSubmissionIDs: comparedSubmissionIDs.filter(
            (s) => s !== submission.id
          ),
          comparisonGroupRowIdentifierID:
            comparedSubmissionIDs.length > 1
              ? comparisonGroupRowIdentifierID
              : undefined,
        },
        () => {
          if (this.listRef) {
            this.listRef.recomputeRowHeights(0);
          }
        }
      );
    } else if (comparedSubmissionIDs.length < 2) {
      this.setState(
        {
          comparedSubmissionIDs: comparedSubmissionIDs.concat(submission.id),
          comparisonGroupRowIdentifierID: submission.GroupRowIdentifierID,
        },
        () => {
          if (this.listRef) {
            this.listRef.recomputeRowHeights(0);
          }
        }
      );
    }
  };

  private canCompareSubmission = (submission: Submission) => {
    const { comparedSubmissionIDs, comparisonGroupRowIdentifierID } =
      this.state;

    return (
      comparedSubmissionIDs.includes(submission.id) ||
      comparedSubmissionIDs.length === 0 ||
      (comparedSubmissionIDs.length === 1 &&
        comparisonGroupRowIdentifierID === submission.GroupRowIdentifierID)
    );
  };

  private launchComparison = async () => {
    const { instanceId, submissions } = this.props;
    const { comparedSubmissionIDs } = this.state;

    if (comparedSubmissionIDs.length !== 2) {
      this.setState({
        isComparing: true,
        generatedFile: undefined,
        comparisonError: new Error(
          'Exactly two submissions must be chosen to be compared'
        ),
      });
      return;
    }

    this.setState({ isComparing: true });
    try {
      const generatedFile = await API.create<GeneratedFile>(
        `/generated_files/scenario_version_comparison`,
        {
          ModelInstanceID: instanceId,
          FromSubmissionID: comparedSubmissionIDs[0],
          ToSubmissionID: comparedSubmissionIDs[1],
          ScenarioID: submissions[0].ScenarioID,
        }
      );

      this.setState({
        generatedFile,
        comparisonError: undefined,
      });
    } catch (e: any) {
      this.setState({
        generatedFile: undefined,
        comparisonError: e,
      });
    }
  };

  private closeComparisonDialog = () => {
    this.setState({
      isComparing: false,
      generatedFile: undefined,
      comparisonError: undefined,
    });
  };
}

export default SubmissionList;
