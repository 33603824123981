import React, { FormEvent, PureComponent } from 'react';
import {
  Button,
  Card,
  Box,
  CardContent,
  Typography,
  FormGroup,
  TextField,
  Autocomplete,
  Alert,
  MenuItem,
  ListItemText,
  AlertTitle,
  Select,
  SelectChangeEvent,
  InputLabel,
  FormControl,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { Add } from '@mui/icons-material';

import { filter, includes, map, toLower } from 'lodash';

import { loadProfile } from '../../services/Auth';
import * as API from '../../services/API';
import { ModelMembership, ModelMembershipRole, ModelRoles, User } from '../../types/models';
import { filterUser } from '../../utils/user';

import { ProfileProps, withProfile } from '../shared/AuthController';
import APICacheContext from '../shared/APICacheContext';
import ErrorList from '../shared/ErrorList';
import Loader, { LoaderInfo } from '../shared/Loader';

import Flex from '../shared/Flex';

import APICache from '../../services/APICache';

import CenteredSpinner from '../shared/CenteredSpinner';

import RegionButton from './RegionButton';

interface Props extends ProfileProps {
  filter: string;
  clientId: number;
  modelId: number;
  memberships: ModelMembership[];
}

interface State {
  isAdding: boolean;
  user?: User;
  role: ModelMembershipRole;
  busy: boolean;
  formErrors: Record<string, string[]>;
  requestError?: Error;
}

const INITIAL_STATE: State = {
  isAdding: false,
  user: undefined,
  role: ModelMembershipRole.Contributor,
  busy: false,
  formErrors: {},
  requestError: undefined,
};

class TeamMemberInvite extends PureComponent<Props, State> {
  public static contextType = APICacheContext;
  public state: State = INITIAL_STATE;

  public render() {
    const { isAdding } = this.state;

    const needs = { users: `/users` };

    return (
      <>
        {isAdding ? (
          <Box my={3}>
            <Card raised>
              <CardContent>
                <Typography variant="h6">Invite a Team Member</Typography>
                <Loader needs={needs} render={this.renderForm} />
              </CardContent>
            </Card>
          </Box>
        ) : (
          <Flex sx={{ justifyContent: 'center' }}>
            <RegionButton endIcon={<Add />} onClick={this.showForm}>
              Invite a Team Member
            </RegionButton>
          </Flex>
        )}
      </>
    );
  }

  private renderForm = ({
    loading,
    errors: loaderErrors,
    data,
  }: LoaderInfo<{ users: User[] }>) => {
    const { busy, role, requestError, formErrors } = this.state;
    const { users = [] } = data;

    if (loading) {
      return (
        <Flex m="auto" alignItems="center" justifyContent="center">
          <CenteredSpinner />
        </Flex>
      );
    }

    if (loaderErrors.length > 0) {
      return (
        <Alert severity="error">
          <AlertTitle>Failed to fetch users</AlertTitle>
        </Alert>
      );
    }

    return (
      <>
        <Flex mt={3} alignItems="center">
          <Box mr={3} flex="1" style={{ minWidth: 0 }}>
            <Autocomplete<User>
              onChange={this.handleUserSelect}
              options={this.filterUsers(users)}
              getOptionLabel={(user) => user.Display_Name}
              getOptionDisabled={this.isItemDisabled}
              renderInput={(params) => (
                <TextField {...params} label="Select a user" />
              )}
              filterOptions={(options, state) =>
                filter(
                  options,
                  (i) =>
                    includes(
                      toLower(i.Display_Name),
                      toLower(state.inputValue)
                    ) ||
                    includes(toLower(i.User_Name), toLower(state.inputValue))
                )
              }
              renderOption={(props, user) => (
                <MenuItem {...props} key={user.id}>
                  <ListItemText>
                    <Typography variant="inherit" noWrap>
                      {user.Display_Name}
                    </Typography>
                  </ListItemText>
                  <Typography variant="inherit" noWrap>
                    {user.User_Name}
                  </Typography>
                </MenuItem>
              )}
            />
          </Box>
          <Box flex="none">
            <FormGroup>
              <FormControl sx={{ m: 1, minWidth: 120 }}>
                <InputLabel id="role">Select a role</InputLabel>
                <Select
                  id="role"
                  margin="none"
                  value={role.toString()}
                  label="Select a role"
                  disabled={busy}
                  sx={{ width: '176px' }}
                  onChange={this.handleRoleChange}
                  size="small"
                >
                  {map(ModelRoles, (i: any) => (
                    <MenuItem key={i.roleValue} value={i.roleValue}>
                      {i.roleName}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </FormGroup>
          </Box>
        </Flex>
        {requestError && (
          <Box mt={2}>
            <Alert severity="error">
              <AlertTitle>Something went wrong</AlertTitle>
              Failed to create membership, check your connection and try again
            </Alert>
          </Box>
        )}
        {Object.values(formErrors).some((errs) => errs.length > 0) && (
          <Box mt={2}>
            <ErrorList errors={formErrors} />
          </Box>
        )}
        <Flex justifyContent="flex-end" mt={3}>
          <Box mr={1}>
            <Button onClick={this.handleCancel} variant="outlined">
              Cancel
            </Button>
          </Box>
          <LoadingButton
            loading={busy}
            disabled={busy}
            color="primary"
            onClick={this.handleSubmit}
            variant="contained"
          >
            Invite
          </LoadingButton>
        </Flex>
      </>
    );
  };

  private filterUsers = (users: User[]) =>
    users
      .filter((user) =>
        filterUser(user, this.props.filter, this.props.clientId)
      )
      .sort((a, b) =>
        a.Display_Name > b.Display_Name
          ? 1
          : b.Display_Name > a.Display_Name
            ? -1
            : 0
      );

  private isItemDisabled = (user: User) =>
    this.props.memberships.some((membership) => membership.User.id === user.id);

  private handleUserSelect = (event: any, user: User | null) => {
    this.setState({ user: user as User });
  };

  private handleRoleChange = (event: SelectChangeEvent) => {
    this.setState({
      role: Number(event.target.value)
    });
  };

  private showForm = () => this.setState({ isAdding: true });
  private handleCancel = () => this.setState(INITIAL_STATE);
  private handleSubmit = async (event: FormEvent) => {
    event.preventDefault();
    event.stopPropagation();

    const { modelId, profile } = this.props;
    const { user, role } = this.state;

    const formErrors: Record<string, string[]> = {
      user: [],
    };

    if (user === undefined) {
      formErrors.user.push('User is required');
    }

    this.setState({ formErrors });

    if (
      Object.values(formErrors).some((errs) => errs.length > 0) ||
      user === undefined
    ) {
      return;
    }

    this.setState({
      busy: true,
      requestError: undefined,
    });
    try {
      await API.create(`/models/${modelId}/model_memberships`, {
        UserID: user.id,
        Role: role,
      });

      await (this.context as APICache).load(
        [`/models/${modelId}/model_memberships`],
        true
      );

      if (profile !== undefined && user.id === profile.User.id) {
        await loadProfile();
      }
    } catch (error: any) {
      this.setState({ busy: false, requestError: error });
      return;
    }

    this.setState(INITIAL_STATE);
  };
}

export default withProfile(TeamMemberInvite);
